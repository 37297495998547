import { Line_Items } from './routes'
import axios from 'axios'

export const getLineItems = () => {
  return axios.get(`${Line_Items}`)
}

export const getLineItemSingle = (id) => {
  return axios.get(`${Line_Items}/${id}/edit`)
}

export const createLineItems = (data) => {
  return axios.post(`${Line_Items}`, data)
}

export const updateLineItems = (id, data) => {
  return axios.put(`${Line_Items}/${id}`, data)
}

export const deleteLineItem = (id) => {
  return axios.delete(`${Line_Items}/${id}`)
}
