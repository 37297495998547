/* eslint-disable react/prop-types */
import React, { createContext, useState } from 'react'

const Usercntxt = createContext()

const UserContext = ({ children }) => {
  const [userState, setUserState] = useState()

  const can = (permissions) =>
    (userState?.permissions || []).find((p) => p === permissions) ? true : false

  const setUser = (value) => {
    setUserState(value)
  }
  return <Usercntxt.Provider value={{ setUser, userState, can }}>{children}</Usercntxt.Provider>
}

export { UserContext, Usercntxt }
