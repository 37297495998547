import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import swal from 'sweetalert'
import Preloader from '../preloader/Preloader'

//services
import { getSubOrderTypeSingle, createSubOrderType, updateSubOrderType } from 'src/services'

//components
import { BreadCrumb, Card } from 'src/components'

const CreateOrUpdate = () => {
  const history = useHistory()
  const { id } = useParams()
  const isUpdating = id !== undefined
  const [loading, setLoading] = useState(isUpdating)
  const [inputList, setInputList] = useState({
    name: '',
    code: '',
    error_list: [],
  })

  useEffect(() => {
    if (isUpdating) {
      getSubOrderTypeSingle(id)
        .then((res) => {
          if (res.data.status === 200) {
            setInputList(res.data.sub_order)
          } else {
            history.push('/sub_order_types')
          }
          setLoading(false)
        })
        .catch(() => {
          history.push('/sub_order_types')
          setLoading(false)
        })
    }
  }, [id, history, isUpdating])

  const handleInput = (e) => {
    e.persist()
    setInputList({ ...inputList, [e.target.name]: e.target.value })
  }

  const submitForm = (e) => {
    e.preventDefault()
    const data = inputList

    const submitAction = isUpdating
      ? () => updateSubOrderType(id, data)
      : () => createSubOrderType(data)

    submitAction()
      .then((res) => {
        if (res.data.status === 200) {
          swal('Success', res.data.message, 'success')
          history.push('/sub_order_types')
        } else if (res.data.status === 400 || res.data.status === 422) {
          setInputList({ ...inputList, error_list: res.data.errors || {} })
        }
      })
      .catch((error) => {
        swal('Error', 'An error occurred', 'error')
      })
  }

  if (loading) {
    return <Preloader />
  }

  const title = 'Sub Order Type'
  const isanupdate = isUpdating ? 'Update' : 'Add'

  const breadcrumb = [
    {
      title: title,
      name: 'Add' + title,
      subtitle: isanupdate,
      subtitleroute: '/sub_order_types',
    },
  ]

  const cardValues = [
    {
      title: title,
      addorupdate: isanupdate,
    },
  ]

  return (
    <div>
      <BreadCrumb data={breadcrumb} />
      <div className="content">
        <div className="card card-primary">
          <Card data={cardValues}>
            <form onSubmit={submitForm}>
              <div className="row">
                {/* First Name */}
                <div className="col-lg-4">
                  <div className="form-group">
                    <label className="pb-2">
                      Charge Rate <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      onChange={handleInput}
                      value={inputList.name}
                      placeholder="Charge Rate Name"
                    />
                    <span className="text-danger">{inputList?.error_list?.name}</span>
                  </div>
                </div>

                {/* Xero Code */}
                <div className="col-lg-4">
                  <div className="form-group">
                    <label className="pb-2">
                      Xero Code <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="code"
                      onChange={handleInput}
                      value={inputList.code}
                      placeholder="Xero Code"
                    />
                    <span className="text-danger">{inputList?.error_list?.code}</span>
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-primary mt-3">
                {isUpdating ? 'Update' : 'Save'}
              </button>
            </form>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default CreateOrUpdate
