import { ProductMix } from './routes'
import axios from 'axios'

export const getProductMix = () => {
  return axios.get(`${ProductMix}`)
}

export const getProductMixSingle = (id) => {
  return axios.get(`${ProductMix}/${id}/edit`)
}

export const createProductMix = (data) => {
  return axios.post(`${ProductMix}`, data)
}

export const updateProductMix = (id, data) => {
  return axios.put(`${ProductMix}/${id}`, data)
}

export const deleteProductMix = (id) => {
  return axios.delete(`${ProductMix}/${id}`)
}
