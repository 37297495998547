import { Crops } from './routes'
import axios from 'axios'

export const getCrops = () => {
  return axios.get(`${Crops}`)
}

export const deleteCrops = (id) => {
  return axios.delete(`${Crops}/${id}`)
}

export const getCropSingle = (id) => {
  return axios.get(`${Crops}/${id}/edit`)
}

export const createCrops = (data) => {
  return axios.post(`${Crops}`, data)
}

export const updateCrops = (id, data) => {
  return axios.put(`${Crops}/${id}`, data)
}
