import React, { useState, useEffect, useRef } from 'react'
import { useHistory, NavLink, useParams } from 'react-router-dom'
import axios from 'axios'
import { CFormSelect, CFormCheck, CFormSwitch } from '@coreui/react'
import swal from 'sweetalert2'
import Preloader from 'src/views/preloader/Preloader'
import Select from 'react-select'
import { v4 as uuidv4 } from 'uuid'
import FileInput from 'src/components/input/Input'
import useUnsavedChangesWarning from 'src/hooks/UnsavedChangesWarning'
import { xeroCodeGenerator } from './utils/Functions'
import { object } from 'prop-types'

//services
import { getOrderTypeSingle } from 'src/services'

function Create(props) {
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning()
  const history = useHistory()
  const ref = useRef()
  let { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [priceLoaded, setPriceLoaded] = useState(false)
  const [loadingOrderType, setLoadingOrderType] = useState(false)
  const [Code, setCode] = useState({
    order_type: '',
    machine_id: '',
    productMix: '',
    custom_spread_rate: '',
    sku_location: '',
    location: '',
    unit: '',
  })
  const [spreadchargerates, setSpreadChargeRates] = useState([])
  const [quantityType, setQuantityType] = useState([])
  const [skuLocation, setSkuLocation] = useState([])
  const [productMixList, setProductMixList] = useState([])
  const [productMix, setProductMix] = useState([])
  const [customer, setCustomer] = useState([])
  const [customerSelectOptoins, setCustomerSelectedOptions] = useState([])
  const [customerAddress, setCustomerAddress] = useState([])
  const [addressSelect, setAddressSelect] = useState([])
  const [agents, setAgents] = useState([])
  const [orderType, setOrderType] = useState([])
  const [subOrderType, setSubOrderType] = useState([])
  const [orderDeliveryZone, setDeliveryZone] = useState([])
  const [orderTypeName, setOrderTypeName] = useState()
  const [machines, setMachines] = useState([])
  const [crops, setCrops] = useState([])
  const [operator, setOperator] = useState([])
  const [lineItems, setLineItems] = useState([])
  const [OrderStatus, setOrderStatus] = useState('In Progress')
  const [errorList, setError] = useState([])
  const [inputList, setInputList] = useState({
    custom_spread_rate: '',
    tons_ordered: '',
    tons_actual: '',
    created_by: '',
    user_name: '',
    agent_id: '',
    order_status: 0,
    order_type: '',
    sub_order_type: '',
    order_delivery_zone: '',
    sku_location: '',
    has_spread_charge_rate: '',
    machine_id: '',
    scheduled_date: '',
    operators: [
      {
        operators_id: '',
      },
    ],
    order_note: '',
    dockets_ready: '0',
    dockets_ready_time: null,
    tons_complete: '0',
    tons_complete_time: null,
    dockets_complete: '0',
    dockets_complete_time: null,

    customer_id: '',
    customer_address: '',
    account_name: '',
    account_contact_name: '',
    terms: '',
    customer_terms_name: '',
    customer_terms_highlight: '',
    customer_reference: '',
    quoted_price: '',
    driver_note: '',
    admin_note: '',
    product_information: [
      {
        docket_numbers: [],
        product_id: null,
        order_list_products: [],
      },
    ],
    other_line_items: [],
    product_mix: [],
  })

  const [spreadList, setSpreadList] = useState({
    machine: '',
    location: '',
    spreadRate: '',
  })

  //xero code generator
  const handleXeroCode = () => {
    if (inputList.order_type === '') {
      console.log('This is empty')
    } else if (inputList.order_type === '2') {
      //check if sku location and machine is not empty
      if (inputList.sku_location === '') {
        console.log('this is empty')
        //if not empty continue the logic
      } else {
        const list = { ...spreadList }
        list.machine =
          inputList.machine_id > 0
            ? machines.find((x) => x.id == inputList.machine_id).machine_code
            : ''

        list.location = skuLocation.find((x) => x.id == inputList.sku_location).xero_code
        const spreadChargeRated =
          inputList.product_information[0].order_list_products[0].spread_charge_rate

        list.spreadRate =
          spreadChargeRated.length > '0'
            ? spreadchargerates.find((x) => x.id == spreadChargeRated).xero_code
            : ''
        setSpreadList(list)
      }
    } else {
      console.log('nothing works')
      const list = { ...spreadList }
      list.machine = ''
      list.location = ''
      list.spreadRate = ''
      setSpreadList(list)
    }
  }

  const loadPrice = async () => {
    setPriceLoaded(true)
    const newList = JSON.parse(JSON.stringify(inputList)) // Deep clone the list to avoid mutation issues
    for (const productInfo of newList.product_information) {
      for (const product of productInfo.order_list_products) {
        product.xero_code = xeroCodeGenerator(
          skuLocation,
          orderType,
          machines,
          product.product_name,
          spreadchargerates,
          productInfo.order_list_products[0].spread_charge_rate,
          quantityType,
          productInfo.order_list_products.length > 1 ? 6 : product.quantity_type,
          inputList,
        )

        try {
          const response = await axios.post('/api/get_xero_price', {
            code: product.xero_code,
          })
          product.unit_price = response.data?.item?.body?.Items[0]?.SalesDetails?.UnitPrice || 'N/A'
        } catch (err) {
          console.error('Failed to fetch price:', err) // Better error logging
          product.unit_price = 'N/A'
        }
      }
    }

    console.log('Updated newList', newList)
    setInputList((prevList) => ({
      ...prevList,
      product_information: newList.product_information,
    }))
    setPriceLoaded(false)
  }

  //handle image changes
  const handleImage = (e, docketIndex, i) => {
    setDirty()

    const list = { ...inputList }
    let reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    const imagename = e.target.files[0].name.split('.').slice(0, -1).join('.')
    reader.onload = function () {
      list.product_information[i].docket_numbers[docketIndex]['imageURL'] = reader.result
      list.product_information[i].docket_numbers[docketIndex]['imageName'] = imagename
      setInputList(list)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  //initial load for select boxes
  useEffect(() => {
    const fetchAPI = async () => {
      const orders = await axios.get('/api/orders')

      if (orders.data.status === 200) {
        setAgents(orders.data.agent)
        setOrderType(orders.data.order_types)
        setSubOrderType(orders.data.sub_order_type)
        setDeliveryZone(orders.data.delivery_zones)
        setMachines(orders.data.machine)
        setCrops(orders.data.crop)
        setOperator(orders.data.operator)

        const newCustomers = orders.data.customerXero?.body.Contacts.map((contact) => {
          const mobilePhone = contact.Phones.find((phone) => phone.PhoneType === 'MOBILE')
          const label = mobilePhone
            ? `${contact.CompanyNumber || 'No Company Number'} | ${contact.Name} | ${
                mobilePhone.PhoneNumber
              }`
            : `${contact.CompanyNumber || 'No Company Number'} | ${contact.Name}`
          return {
            value: contact.ContactID,
            label: label,
          }
        })

        if (newCustomers && newCustomers.length > 0) {
          setCustomer((prevCustomers) => [...prevCustomers, ...newCustomers])
        }
        setSkuLocation(orders.data.sku_location)
        setQuantityType(orders.data.quantity)
        setSpreadChargeRates(orders.data.spread_charge)
        orders.data.productmix.map((data) => {
          let daratow = {
            value: data.id,
            label: data.product_docket,
          }
          setProductMixList((data) => [...data, daratow])

          return null
        })
        setProductMix(orders.data.products)
        orders.data.products.map((data) => {
          let daratow = {
            value: data.id,
            label: data.product_code,
          }
          setProductMixList((data) => [...data, daratow])
          return null
        })
        console.log(productMixList)
        setLineItems(orders.data.line_item)
      }
      if (id) {
        const orders = await axios.get(`api/edit_orders/${id}`)
        const getCustomer = await axios.get(
          `api/edit_customers_orders/${orders.data.orders.customer_id}`,
        )

        getOrderTypeSingle(orders.data.orders.order_type)
          .then((res) => {
            if (res) {
              setOrderTypeName(res.data.order_types.name)
            }
          })
          .finally(() => {
            setLoading(false)
          })

        if (orders.data.status === 200) {
          const list = { ...inputList }
          console.log(inputList)
          setCustomerSelectedOptions({
            value: getCustomer.data.customer.id,
            label:
              getCustomer.data.customer.code +
              ' | ' +
              getCustomer.data.customer.account_name +
              ' | ' +
              getCustomer.data.customer.phone_mobile,
          })
          list.customer_id = getCustomer.data.customer.id
          list.account_name = getCustomer.data.customer.account_name
          list.account_contact_name = getCustomer.data.customer.account_contact_name
          list.customer_terms_name = getCustomer.data.customer.customer_terms.name
          list.customer_terms_highlight = getCustomer.data.customer.customer_terms.highlight

          const newItem = { ...list, ...orders.data.orders }
          setInputList(newItem)

          setCustomerAddress(getCustomer.data.customer.customer_address)
        }
        const getSelectdAddress = await axios.get(
          `api/edit_addresse/${orders.data.orders.customer_address}`,
        )
        if (getSelectdAddress.data.status === 200) {
          setAddressSelect(getSelectdAddress.data.address)
        }
      } else {
        const User = await axios.get('/api/edit_profile')
        if (User.data.status === 200) {
          const list = { ...inputList }
          list.created_by = User.data.user.id
          list.user_name = User.data.user.name
          setInputList(list)
        }
      }
      setLoading(false)
    }
    fetchAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //load the data from product mix and product ID
  const handleChangeProductMix = (e, index) => {
    setDirty()

    const { value, name } = e
    const list = { ...inputList }
    list.product_information[index].product_id = value
    setInputList(list)
  }

  //load the data from product mix and product ID
  const handleAddNewProduct = (e, index) => {
    setDirty()

    e.preventDefault()
    var min = 1
    var max = 100
    var rand = Math.floor(min + Math.random() * (max - min))
    e.target.disabled = true
    e.target.innerText = 'Adding...'
    var id = inputList.product_information[index].product_id

    axios
      .get(`api/edit_product_mix/${id}`)
      .then((res) => {
        if (res.data.status === 200 && res.data.product_mix) {
          const list = { ...inputList }
          let newData = {
            product_mix_id: res.data.product_mix.id,
            product_name: res.data.product_mix.product_code,
            spread_charge_rate: '',
            quantity: '',
            quantity_type: '',
            xero_code: '',
            unit_price: rand,
            mix_percentage: '100',
            id: '',
          }
          list.product_information[index].order_list_products.push(newData)
          setInputList(list)
          loadPrice()
          e.target.disabled = false
          e.target.innerText = 'Add Product'
        } else {
          axios
            .get(`api/edit_products/${id}`)
            .then((res) => {
              if (res.data && res.data.status === 200 && res.data.product) {
                const objectArrayId = []
                const productMixIdToBeAdded = res.data.product.id

                inputList.product_information[index].order_list_products.forEach((product) => {
                  objectArrayId.push(Number(product.product_mix_id))
                })

                if (!objectArrayId.includes(productMixIdToBeAdded)) {
                  const list = { ...inputList }
                  let newData = {
                    product_mix_id: res.data.product.id,
                    product_name: res.data.product.product_code,
                    spread_charge_rate: '',
                    quantity: '',
                    quantity_type: '',
                    mix_percentage: '100',
                    xero_code: '',
                    unit_price: 'N/A',
                    id: '',
                  }
                  list.product_information[index].order_list_products.push(newData)
                  setInputList(list)
                  loadPrice()
                  e.target.disabled = false
                  e.target.innerText = 'Add Product'
                } else {
                  swal.fire('Error', 'Cannot duplicate Product / Product Mix', 'error')
                  e.target.disabled = false
                  e.target.innerText = 'Add Product'
                }
              } else if (res.data && res.data.status === 404) {
                swal.fire('Error', "Couldn't Find The Product / Product Mix", 'error')
                e.target.disabled = false
                e.target.innerText = 'Add Product'
              } else {
                swal.fire('Error', 'Unexpected error occurred', 'error')
                e.target.disabled = false
                e.target.innerText = 'Add Product'
              }
            })
            .catch((error) => {
              swal.fire('Error', error.message, 'error')
              e.target.disabled = false
              e.target.innerText = 'Add Product'
            })
        }
      })
      .catch((error) => {
        swal.fire('Error', error.message, 'error')
        e.target.disabled = false
        e.target.innerText = 'Add Product'
      })
  }

  //load the address data when select the customer
  const handleChangeCustomerID = (e) => {
    setDirty()
    const { value } = e
    console.log(value)
    setAddressSelect([])
    setCustomerSelectedOptions(e)
    axios.get(`api/edit_customers/${value}`).then((res) => {
      console.log('customers', res.data.customer)
      if (res.data.status === 200) {
        const list = { ...inputList }
        list.customer_id = res.data?.customer?.id
        list.account_name = res?.data?.customer?.account_name
        list.account_contact_name = res?.data?.customer?.account_contact_name
        list.terms = res?.data?.customer?.terms
        list.customer_terms_name = res?.data?.customer?.customer_terms?.name
        list.customer_terms_highlight = res?.data?.customer?.customer_terms?.highlight

        setInputList(list)
        console.log(list)

        setCustomerAddress(res?.data?.customer?.customer_address)
      } else {
        const list = { ...inputList }
        list.customer_id = ''
        list.account_name = ''
        list.account_contact_name = ''
        list.terms = ''
        list.customer_terms_name = ''
        list.customer_terms_highlight = ''

        setInputList(list)
        setCustomerAddress([])
        setAddressSelect([])
        swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Customer details could not be found please select a valid customer code',
        })
      }
    })
  }

  //load the additional data when select the addresses
  const HandleChangeAddress = async (e) => {
    setDirty()

    const { name, value } = e.target

    const list = { ...inputList }
    list[name] = value
    setInputList(list)
    const addressDetails = await axios.get(`/api/edit_addresse/${value}`)
    if (addressDetails.data.status === 200) {
      setAddressSelect(addressDetails.data.address)
    }
  }

  //handle changes for customer information
  const handleChangeLevel1 = async (e) => {
    const { name, value } = e.target
    console.log('1', name)

    const list = { ...inputList }
    list[name] = value

    setInputList(list)
    setDirty()
  }

  const handleChangeLevel1XeroCode = async (e) => {
    e.preventDefault()
    const { name, value } = e.target

    const list = { ...inputList }
    list[name] = value

    console.log('3', name)

    setInputList(list)
    setDirty()

    if (
      name === 'order_type' ||
      name === 'order_delivery_zone' ||
      name === 'sku_location' ||
      name === 'machine_id'
    ) {
      loadPrice()
    } else {
      console.log('Error')
    }

    // Disable the field initially

    // Custom handling for 'order_type' field
    if (name === 'order_type') {
      try {
        const res = await getOrderTypeSingle(value)
        if (res.data.status === 200) {
          setOrderTypeName(res.data.order_types.name)
          list.has_spread_charge_rate = res.data.order_types.has_spread_charge_rate
        } else {
          history.push('/orders')
        }
      } catch (error) {
        history.push('/orders')
      }
    }
  }

  const handleChangeLevel3XeroCode = async (e, i, j, arrayname, arrayname2) => {
    // Enable loading state at the start
    const { name, value } = e.target

    const list = { ...inputList }
    list[arrayname][i][arrayname2][j][name] = value
    setInputList(list)
    setDirty()

    if (name === 'spread_charge_rate' || name === 'quantity_type') {
      loadPrice()
    } else {
      console.log('Error')
    }
  }

  const handleBlurDecimalLevel1 = (e) => {
    setDirty()
    const { name, value } = e.target
    const list = { ...inputList }

    var num = parseFloat(value)
    console.log(num)
    var cleanNum = num.toFixed(3)

    list[name] = cleanNum
    setInputList(list)
  }

  //handle changes for customer information
  const handleChangeLevel2 = (e, i, arrayname) => {
    setDirty()

    const { name, value } = e.target

    console.log('2', name)

    const list = { ...inputList }
    list[arrayname][i][name] = value
    setInputList(list)
  }

  const handleBlurDecimalLevel2 = (e, i, arrayname) => {
    setDirty()

    const { name, value } = e.target
    const list = { ...inputList }

    var num = parseFloat(value)
    var cleanNum = num.toFixed(3)

    list[arrayname][i][name] = cleanNum
    setInputList(list)
  }

  //handle changes for customer information
  const handleChangeLevel4 = (e, i, j, arrayname, arrayname2) => {
    setDirty()
    const { name, value } = e.target
    const list = { ...inputList }

    console.log(name)

    /* if (name == 'spread_charge_rate') {
      setCode((prevState) => ({
        ...prevState,
        [name]: value,
      }))
      xeroCodeGenerator(Code, inputList)
    } */

    list[arrayname][i][arrayname2][j][name] = value
  }

  const handleChangeLevel3 = async (e, i, j, arrayname, arrayname2) => {
    // Enable loading state at the start

    const { name, value } = e.target
    console.log('3', name)

    const list = { ...inputList }
    list[arrayname][i][arrayname2][j][name] = value
    setInputList(list)
    setDirty()

    if (name === 'spread_charge_rate') {
      loadPrice()
    } else {
      console.log('Error')
    }
  }

  const removeLevel3 = (e, i, j, arrayname, arrayname2) => {
    setDirty()

    e.preventDefault()
    const list = { ...inputList }
    list[arrayname][i][arrayname2].splice(j, 1)
    setInputList(list)
  }

  //handle changes for order information
  const handleChangeOrderInformation = (e) => {
    setDirty()

    const { name, value } = e.target
    const list = { ...inputList }
    list[name] = value
    setInputList(list)
  }

  //handle chages for order type
  const handleChangeOrderType = async (e) => {
    setDirty()

    const { name, value } = e.target
    const order_types = await axios.get(`/api/edit_order_type/${value}`)
    if (order_types.data.status === 200) {
      setOrderTypeName(order_types.data.order_types.name)
      const list = { ...inputList }
      list.has_spread_charge_rate = order_types.data.order_types.has_spread_charge_rate
      list[name] = value
      setInputList(list)
    }
  }

  useEffect(() => {
    if (
      inputList.dockets_ready === '2' &&
      inputList.dockets_complete === '0' &&
      inputList.tons_complete === '0'
    ) {
      setOrderStatus('Will Advise')
    } else if (
      inputList.dockets_ready === '2' &&
      inputList.dockets_complete === '1' &&
      inputList.tons_complete === '0'
    ) {
      setOrderStatus('Will Advise')
    } else if (
      inputList.dockets_ready === '2' &&
      inputList.dockets_complete === '0' &&
      inputList.tons_complete === '1'
    ) {
      setOrderStatus('Will Advise')
    } else if (
      inputList.dockets_ready === '2' &&
      inputList.dockets_complete === '1' &&
      inputList.tons_complete === '1'
    ) {
      setOrderStatus('Will Advise')
    } else if (
      inputList.dockets_ready === '1' &&
      inputList.dockets_complete === '0' &&
      inputList.tons_complete === '0'
    ) {
      setOrderStatus('Ready')
    } else if (
      inputList.dockets_ready === '1' &&
      inputList.dockets_complete === '1' &&
      inputList.tons_complete === '0'
    ) {
      setOrderStatus('Ready')
    } else if (
      inputList.dockets_ready === '1' &&
      inputList.dockets_complete === '0' &&
      inputList.tons_complete === '1'
    ) {
      setOrderStatus('Ready')
    } else if (
      inputList.dockets_ready === '1' &&
      inputList.dockets_complete === '1' &&
      inputList.tons_complete === '1'
    ) {
      setOrderStatus('Complete')
    }
    if (inputList.dockets_ready === '3') {
      setOrderStatus('In Progress')
    }
    if (inputList.dockets_ready === '0') {
      setOrderStatus('')
    }
    if (inputList.order_status === 1) {
      setOrderStatus('Complete')
    }
  }, [inputList])

  //handle chages for checkboxes
  const handleCheckBox = (e) => {
    setDirty()

    const { name, checked } = e.target
    const nameTime = name.concat('_time')
    const list = { ...inputList }

    var MyDate = new Date()
    var MyDateString

    MyDate.setDate(MyDate.getDate())

    // MyDateString =
    //   ('0' + MyDate.getDate()).slice(-2) +
    //   '-' +
    //   ('0' + (MyDate.getMonth() + 1)).slice(-2) +
    //   '-' +
    //   MyDate.getFullYear()

    MyDateString =
      MyDate.getFullYear() +
      '-' +
      ('0' + (MyDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + MyDate.getDate()).slice(-2)

    let total_rows = 0
    for (let i = 0; i < inputList.product_information.length; i++) {
      for (let j = 0; j < inputList.product_information[i].docket_numbers.length; j++) {
        total_rows += 1
      }
    }

    if (name === 'dockets_complete' && (total_rows <= 0 || inputList.tons_complete === 0)) {
      swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Atlease one docket should be added and tons complete should be checked',
      })
      return
    }
    if (checked) {
      list[name] = checked ? '1' : '0'
      list[nameTime] = MyDateString

      setInputList(list)
    } else {
      swal
        .fire({
          title: 'Are you sure you want to proceed?',
          text: 'Unchecking will reset the Data and Time and may effect Order Complete Status!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Proceed!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            swal.fire(
              'Unchecked!',
              'Date and Time has been reset. Order is in progress.',
              'Success',
            )
            list[nameTime] = null
            list[name] = '0'
            setInputList(list)
          } else if (result.isDenied) {
            swal.fire('Changes are not saved', '', 'info')
            list[name] = '1'
            setInputList(list)
          }
        })
    }
  }

  //handle chages for checkboxes
  const handleRadio = (e) => {
    setDirty()

    const { name, value } = e.target
    const list = { ...inputList }
    list[name] = value
    setInputList(list)
  }

  //handle chages for checkboxes
  const HandleComplleted = (e) => {
    setDirty()

    const { name, checked } = e.target
    const list = { ...inputList }

    if (checked) {
      list.order_status = 1
      swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Order will be marked complete',
      })
    } else {
      list.order_status = 0
    }

    setInputList(list)
    console.log(list)
  }
  // //Add a new product row
  // const addProductRow = (e) => {
  //   e.preventDefault()
  //   setInputList({
  //     ...inputList,
  //     product_information: [
  //       ...inputList.product_information,
  //       {
  //         docket_numbers: [
  //           /*{ docket_number: '', weight: '', imageURL: '' }*/
  //         ],
  //         product_mix_id: '',
  //         product_name: '',
  //         spread_charge_rate: '',
  //         quantity: '',
  //         quantity_type: '',
  //         xero_code: '',
  //         unit_price: '',
  //         id: '',
  //       },
  //     ],
  //   })
  // }

  // //remove a product row
  // const removeRoductRow = (e, i) => {
  //   e.preventDefault()
  //   const list = { ...inputList }
  //   list.product_information.splice(i, 1)
  //   setInputList(list)
  // }

  //Add a new product row
  const addLineItems = (e) => {
    setDirty()

    e.preventDefault()
    setInputList({
      ...inputList,
      other_line_items: [
        ...inputList.other_line_items,
        {
          type: '',
          description: '',
          quantity: '',
          amount: '',
        },
      ],
    })
  }

  //remove a product row
  const removeLineItems = (e, i) => {
    setDirty()

    e.preventDefault()
    const list = { ...inputList }
    list.other_line_items.splice(i, 1)
    setInputList(list)
  }

  //handle chages for other line items
  const handleChangeOtherLineItems = (e, i) => {
    setDirty()

    const { name, value } = e.target
    console.log(name)

    const list = { ...inputList }
    list.other_line_items[i][name] = value
    setInputList(list)
  }

  //add doker fields - Add button
  const handleAddDocket = (e, i) => {
    setDirty()

    e.preventDefault()
    const list = { ...inputList }
    list.product_information[i].docket_numbers.push({
      sku_location: '',
      docket_number: '',
      docket_date: '',
      weight: '',
      imageName: '',
      imageURL: '',
    })
    setInputList(list)

    var sum = 0
    inputList.product_information[i].docket_numbers.map(
      (numbers) => (sum += Number(numbers.weight)),
    )
  }

  //calculate tons value
  const quantityTotal = (e, i, j, arrayname, arrayname2) => {
    const list = { ...inputList }
    const quantity = []
    //get total tonnes
    for (let i = 0; i < list.product_information.length; i++) {
      if (list.product_information[i].order_list_products.length > 1) {
        for (let j = 0; j < list.product_information[i].order_list_products.length; j++) {
          quantity.push(+list.product_information[i].order_list_products[j].quantity).toFixed(3)
        }
      } else {
        for (let j = 0; j < list.product_information[i].order_list_products.length; j++) {
          quantity.push(+list.product_information[i].order_list_products[j].quantity).toFixed(3)
          list.product_information[i].order_list_products[j].mix_percentage = 100
        }
      }
    }
    list.tons_ordered = quantity.reduce((v1, v2) => v1 + v2, 0).toFixed(3)

    //get percentage
    for (let i = 0; i < list.product_information.length; i++) {
      if (list.product_information[i].order_list_products.length > 1) {
        for (let j = 0; j < list.product_information[i].order_list_products.length; j++) {
          list.product_information[i].order_list_products[j].mix_percentage = Number(
            (list.product_information[i].order_list_products[j].quantity * 100) / list.tons_ordered,
          ).toFixed(3)
        }
      } else {
        for (let j = 0; j < list.product_information[i].order_list_products.length; j++) {
          quantity.push(+list.product_information[i].order_list_products[j].quantity).toFixed(3)
          list.product_information[i].order_list_products[j].mix_percentage = 100
        }
      }
    }
    setInputList(list)
  }

  //calculate percentage value
  const percentatgeCalc = (e, i, j, arrayname, arrayname2) => {
    const list = { ...inputList }
    for (let i = 0; i < list.product_information.length; i++) {
      if (list.product_information[i].order_list_products.length > 1) {
        for (let j = 0; j < list.product_information[i].order_list_products.length; j++) {
          var percentage =
            (Number(list.tons_ordered).toFixed(3) *
              Number(list.product_information[i].order_list_products[j].mix_percentage).toFixed(
                3,
              )) /
            100

          list.product_information[i].order_list_products[j].quantity =
            Number(percentage).toFixed(3)
        }
      } else {
        for (let j = 0; j < list.product_information[i].order_list_products.length; j++) {
          list.product_information[i].order_list_products[j].mix_percentage = 100
        }
      }
    }
    setInputList(list)
  }

  //3 decimal point
  const percentatgeCalcBlur = (e, i, j, arrayname, arrayname2) => {
    const { name, value } = e.target
    const list = { ...inputList }
    list[arrayname][i][arrayname2][j][name] = Number(value).toFixed(3)
    setInputList(list)
  }

  // 3 decimal point
  const quantityTotalBlur = (e, i, j, arrayname, arrayname2) => {
    const { name, value } = e.target
    const list = { ...inputList }
    list[arrayname][i][arrayname2][j][name] = Number(value).toFixed(3)
    setInputList(list)
  }

  //enable disable product mix
  const enableMix = (e) => {
    e.preventDefault()
    const list = { ...inputList }
    document.getElementsByName('tons_ordered')[0].removeAttribute('disabled')
    for (let i = 0; i < list.product_information.length; i++) {
      for (let j = 0; j < list.product_information[i].order_list_products.length; j++) {
        console.log('working till here')
        document.getElementsByName('mix_percentage')[j].removeAttribute('disabled')
        document.getElementsByName('quantity')[j].setAttribute('disabled', '')
        const quant_clr = document.getElementById('quantity')
        const mix_clr = document.getElementById('mix_percentage')
        quant_clr.style.color = 'black'
        mix_clr.style.color = 'blue'
      }
    }
  }

  //enable disable quantity
  const enableQuantity = (e) => {
    e.preventDefault()
    const list = { ...inputList }
    document.getElementsByName('tons_ordered')[0].setAttribute('disabled', '')
    for (let i = 0; i < list.product_information.length; i++) {
      for (let j = 0; j < list.product_information[i].order_list_products.length; j++) {
        console.log('working till here')
        document.getElementsByName('mix_percentage')[j].setAttribute('disabled', '')
        document.getElementsByName('quantity')[j].removeAttribute('disabled')
        const quant_clr = document.getElementById('quantity')
        const mix_clr = document.getElementById('mix_percentage')
        quant_clr.style.color = 'blue'
        mix_clr.style.color = 'black'
      }
    }
  }

  //calculate mix percentage

  /* useEffect(() => {
    const list = { ...inputList }
    for (let i = 0; i < list.product_information.length; i++) {
      if (list.product_information[i].order_list_products.length > 1) {
        for (let j = 0; j < list.product_information[i].order_list_products.length; j++) {
          var percentage =
            (Number(list.tons_ordered).toFixed(3) *
              Number(list.product_information[i].order_list_products[j].mix_percentage).toFixed(
                3,
              )) /
            100

          list.product_information[i].order_list_products[j].quantity =
            Number(percentage).toFixed(3)
        }
      } else {
        for (let j = 0; j < list.product_information[i].order_list_products.length; j++) {
          list.product_information[i].order_list_products[j].mix_percentage = 100
        }
      }
    }

    setInputList(list)
  }, [JSON.stringify(inputList.product_information), inputList.tons_ordered]) */

  //set xeo codes

  useEffect(() => {}, [
    inputList?.product_information[0]?.order_list_products[0]?.product_name,
    inputList?.product_information[0]?.order_list_products[0]?.spread_charge_rate,
    inputList?.product_information[0]?.order_list_products[0]?.quantity_type,
    inputList.order_type,
    inputList.sku_location,
    inputList.order_delivery_zone,
    inputList.machine_id,
  ])

  useEffect(() => {
    const list = { ...inputList }

    let tons_actual = 0
    for (let i = 0; i < list.product_information.length; i++) {
      for (let j = 0; j < list.product_information[i].docket_numbers.length; j++) {
        if (Number(list.product_information[i].docket_numbers[j].weight))
          tons_actual += Number(list.product_information[i].docket_numbers[j].weight)
      }
    }
    list.tons_actual = tons_actual

    setInputList(list)
  }, [JSON.stringify(inputList.product_information)])

  const handleRemoveDocket = (e, docketIndex, i) => {
    setDirty()

    e.preventDefault()
    const list = { ...inputList }
    list.product_information[i].docket_numbers.splice(docketIndex, 1)
    setInputList(list)
  }

  //add opperators - Add button
  const handleAddOperators = (e) => {
    setDirty()

    e.preventDefault()

    const list = { ...inputList }

    list.operators.push({ operators_id: '' })
    setInputList(list)
    console.log('Exisiting', inputList)

    /*  list.operators.map((id, i) => {
      inputList.operators.map((opt_id, i) => {
        if (id.operators_id === null) {
          console.log('Select')
        } else if (id.operators_id === opt_id.operators_id) {
          console.log('Duplicate')
        } else {
          list.operators.push({ operators_id: '' })
          setInputList(list)
        }
      })
    }) */
  }

  //remove opperators - remove button button
  const handleRemoveOperators = (e, i) => {
    setDirty()

    e.preventDefault()
    const list = { ...inputList }
    list.operators.splice(i, 1)
    setInputList(list)
  }

  //send data to the API
  const submitOrders = (e) => {
    e.preventDefault()
    const status = mixpercentage()
    console.log('updatedList', inputList)
    if (status) {
      if (id) {
        axios.put(`/api/update_orders/${id}`, inputList).then((res) => {
          if (res.data.status === 200) {
            swal.fire({
              icon: 'success',
              title: 'Success',
              text: res.data.message,
            })
            setError([])
            setPristine()
            history.push(`/orders/update/${res.data.id}`)
          } else if (res.data.status === 400) {
            setError(res.data.errors)
          } else if (res.data.status === 404) {
            swal.fire({
              icon: 'Error',
              title: 'Error',
              text: res.data.message,
            })
            history.push(`/orders/update/${res.data.id}`)
          }
        })
      } else {
        axios.post(`/api/create_order`, inputList).then((res) => {
          if (res.data.status === 200) {
            swal.fire({
              icon: 'success',
              title: 'Success',
              text: res.data.message,
            })
            setError([])
            setPristine()
            history.push(`/orders/update/${res.data.id}`)
          } else if (res.data.status === 400) {
            swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: 'Please fill the required fields',
            })
            setError(res.data.errors)
          }
        })
      }
    }
  }

  const duplicateOrder = (e) => {
    e.preventDefault()

    const status = mixpercentage()

    if (status) {
      axios.get(`/api/edit_profile`).then((res) => {
        if (res.data.status === 200) {
          const list = { ...inputList }
          list.created_by = res.data.user.id
          list.user_name = res.data.user.name
          setInputList(list)
        }
      })

      axios.post(`/api/create_order`, inputList).then((res) => {
        if (res.data.status === 200) {
          swal.fire({
            icon: 'success',
            title: 'Success',
            text: res.data.duplicate,
          })
          setPristine()
          setError([])
          history.push(`/orders/update/${res.data.id}`)
        } else if (res.data.status === 400) {
          setError(res.data.errors)
          console.log('errorList', errorList)
        }
      })
    } else {
      console.log('Status is error')
    }
  }

  // calculate tons actuual
  const mixpercentage = (e) => {
    let sum = 0
    const list = { ...inputList }

    if (list.product_information[0].order_list_products.length > 0) {
      for (let i = 0; i < list.product_information.length; i++) {
        for (let j = 0; j < list.product_information[i].order_list_products.length; j++) {
          if (list.product_information[i].order_list_products[j].mix_percentage === 0) {
            swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: 'The mix percentage cannot be empty',
            })
            return false
          }
          sum += Number(list.product_information[i].order_list_products[j].mix_percentage)
        }

        if (Math.abs(sum - 100) > 0.1) {
          swal.fire({
            icon: 'warning',
            title: 'Warning',
            text: 'The mix percentage for each product set should add up to approximately 100%',
          })
          return false
        }

        sum = 0
      }
    }

    return true
  }

  //send data to the API
  const saveAndClose = (e) => {
    e.preventDefault()

    const status = mixpercentage()

    if (status) {
      if (id) {
        axios.put(`/api/update_orders/${id}`, inputList).then((res) => {
          if (res.data.status === 200) {
            swal.fire({
              icon: 'success',
              title: 'Success',
              text: res.data.message,
            })
            setError([])
            setPristine()
            history.push('/orders')
          } else if (res.data.status === 400) {
            setError(res.data.errors)
          } else if (res.data.status === 404) {
            swal.fire({
              icon: 'Error',
              title: 'Error',
              text: res.data.message,
            })
            history.push('/orders')
          }
        })
      } else {
        axios.post(`/api/create_order`, inputList).then((res) => {
          if (res.data.status === 200) {
            swal.fire({
              icon: 'success',
              title: 'Success',
              text: res.data.message,
            })
            setError([])
            setPristine()
            history.push(`/orders`)
          } else if (res.data.status === 400) {
            swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: 'Please fill the required fields',
            })
            setError(res.data.errors)
          }
        })
      }
    }
  }

  const xeroCodeGeneratorMix = () => {
    let mc = machines?.find((x) => x.id == inputList.machine_id)
    let sku = skuLocation?.find((x) => x.id == inputList.sku_location)
    return (
      (mc?.machine_code ? mc?.machine_code : 'N/A') +
      'MIX.' +
      (mc?.machine_code ? sku?.xero_code : 'N/A')
    )
  }

  const xeroSkuGeneratorMix = () => {
    const products = inputList.product_information[0].order_list_products

    let totalProportionatePrice = 0

    products.forEach((product) => {
      const mixPercentage = parseFloat(product.mix_percentage).toFixed(3) // Given as 10%
      const unitPrice = parseFloat(product.unit_price).toFixed(3)
      const proportionatePrice = (mixPercentage / 100) * unitPrice

      totalProportionatePrice += proportionatePrice
    })

    // The function can return the total values or anything else required
    return totalProportionatePrice.toFixed(3)
  }

  const mixCodeValue = xeroCodeGeneratorMix()

  const mixSkuValue = xeroSkuGeneratorMix()

  if (loading) {
    return <Preloader />
  }
  return (
    <div className="content-wrapper">
      <nav aria-label="breadcrumb p-3">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <NavLink to="/">Dashboard</NavLink>
          </li>
          <li className="breadcrumb-item">
            <NavLink to="/orders">Open Orders</NavLink>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {id ? 'Update order' : 'Add New Order'}
          </li>
        </ol>
      </nav>
      {Prompt}
      <h3 className="text-dark"> {id ? 'Update order' : 'Add New Order'}</h3>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6 p-0"></div>
          </div>
        </div>
      </div>

      {/* body content dashboard */}
      <div className="content">
        <div className="card">
          <div className="card-header">
            <div className="d-flex flex-row justify-content-between">
              <div>
                <h5 className="card-title pt-2"> {id ? 'Update order' : 'Add Order'}</h5>
              </div>
              <div className="">
                <button type="submit" className="btn btn-primary" onClick={(e) => submitOrders(e)}>
                  {id ? 'Update' : 'Save'}
                </button>
                <button
                  type="submit"
                  onClick={(e) => saveAndClose(e)}
                  className="btn btn-primary ms-3"
                >
                  {id ? 'Update And Close' : 'Save And Close'}
                </button>
                {id != null && (
                  <button onClick={(e) => duplicateOrder(e)} className="btn btn-info ms-3">
                    Duplicate Entry
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="card-body">
            <form onSubmit={submitOrders} id="product_form">
              <div className="card-body">
                <div className="row mb-3">
                  <h5>Order Status</h5>
                  {/* {id && ( */}
                  <div className="col">
                    <span>Order ID: {inputList.order_id}</span> <br></br>
                    <span>Status: {OrderStatus}</span>
                    <br></br>
                    <span>Created At: {inputList.created_at} </span>
                  </div>
                  {/* )} */}
                  <div className="col">
                    <CFormCheck
                      onChange={(e) => handleRadio(e)}
                      type="radio"
                      name="dockets_ready"
                      label="Deselect"
                      value={0}
                      checked={inputList.dockets_ready === '0'}
                    />

                    <CFormCheck
                      onChange={(e) => handleRadio(e)}
                      type="radio"
                      name="dockets_ready"
                      label="In Progress"
                      value={3}
                      checked={inputList.dockets_ready === '3'}
                    />

                    <CFormCheck
                      onChange={(e) => handleRadio(e)}
                      type="radio"
                      name="dockets_ready"
                      label="Ready"
                      value={1}
                      checked={inputList.dockets_ready === '1'}
                    />

                    <CFormCheck
                      onChange={(e) => handleRadio(e)}
                      type="radio"
                      name="dockets_ready"
                      label="Will Advise"
                      value={2}
                      checked={inputList.dockets_ready === '2'}
                    />
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <CFormCheck
                        onChange={(e) => handleCheckBox(e)}
                        name="tons_complete"
                        checked={Number(inputList.tons_complete)}
                        label="Tons Complete"
                        //checked={inputList.tons_complete}
                      />
                      {inputList.tons_complete_time === null ? (
                        ''
                      ) : (
                        <input
                          className="form-control w-50 ms-2"
                          type="date"
                          name="tons_complete_time"
                          onChange={(e) => handleChangeLevel1(e)}
                          value={inputList.tons_complete_time}
                        />
                      )}
                      <span className="text-danger">{errorList['tons_complete']}</span>

                      <CFormCheck
                        onChange={(e) => handleCheckBox(e)}
                        name="dockets_complete"
                        className="mt-2"
                        checked={Number(inputList.dockets_complete)}
                        label="Dockets Complete"
                        //    checked={inputList.dockets_complete === '1'}
                      />
                      {inputList.dockets_complete_time === null ? (
                        ''
                      ) : (
                        <input
                          className="form-control w-50 ms-2"
                          type="date"
                          name="dockets_complete_time"
                          onChange={(e) => handleChangeLevel1(e)}
                          value={inputList.dockets_complete_time}
                        />
                      )}
                      <span className="text-danger">{errorList['dockets_complete']}</span>

                      <CFormSwitch
                        label="Completed"
                        name="order_status"
                        onChange={(e) => HandleComplleted(e)}
                        checked={inputList.order_status == 1}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label className="pb-2">Scheduled Date</label>
                      <input
                        className="form-control"
                        type="date"
                        placeholder="User Created By"
                        name="scheduled_date"
                        onChange={(e) => handleChangeLevel1(e)}
                        value={inputList.scheduled_date}
                      />
                      <span className="text-danger">{errorList['scheduled_date']}</span>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <h5>Customer Information</h5>
                <div className="row">
                  {/* Created By */}
                  <div className="col-lg-6 mb-3">
                    <div className="form-group">
                      <label className="pb-2">
                        Customer Code <span className="text-danger">*</span>
                      </label>
                      <Select
                        onChange={(e) => handleChangeCustomerID(e)}
                        aria-label="Default select example"
                        name="customer_id"
                        defaultValue={customerSelectOptoins}
                        options={customer}
                        disabled={priceLoaded}
                      />

                      <span className="text-danger">{errorList['customer_id']}</span>
                    </div>
                  </div>

                  {/* Created By */}
                  <div className="col-lg-3 mb-3">
                    <div className="form-group">
                      <label className="pb-2">Account Name</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Customer Name"
                        name="account_name"
                        value={inputList.account_name}
                        disabled
                      />
                    </div>
                  </div>

                  {/* Created By */}
                  <div className="col-lg-3 mb-3">
                    <div className="form-group">
                      <label className="pb-2">Account Contact Name</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Customer Name"
                        name="account_contact_name"
                        value={inputList.account_contact_name}
                        disabled
                      />
                    </div>
                  </div>
                  {/* Created By */}
                  <div className="col-lg-3 mb-3">
                    <div className="form-group">
                      <label className="pb-2">Terms</label>
                      <input
                        className={
                          inputList.customer_terms_highlight === '0'
                            ? 'form-control bg-danger text-white'
                            : 'form-control'
                        }
                        type="text"
                        placeholder="Terms"
                        name="customer_terms_name"
                        onChange={(e) => handleChangeLevel1(e)}
                        value={inputList.customer_terms_name}
                        disabled
                      />
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Terms"
                        name="terms"
                        onChange={(e) => handleChangeLevel1(e)}
                        value={inputList.terms}
                        disabled
                        hidden
                      />
                      <span className="text-danger">{errorList['terms']}</span>
                    </div>
                  </div>

                  {/* Address */}
                  <div className="col-lg-4 mb-4">
                    <div className="form-group">
                      <label className="pb-2">Address</label>
                      <CFormSelect
                        aria-label="Default select example"
                        name="customer_address"
                        onChange={HandleChangeAddress}
                        value={inputList.customer_address}
                        disabled={inputList.customer_id === ''}
                      >
                        <option value={0}>-- select --</option>
                        {customerAddress?.map((item, i) => {
                          return (
                            <option value={item.id} key={i}>
                              {item.address}
                            </option>
                          )
                        })}
                      </CFormSelect>
                      <span className="text-danger">{errorList['customer_address']}</span>
                    </div>
                  </div>

                  {/* Quoted Price */}
                  <div className="col-lg-3 mb-3">
                    <div className="form-group">
                      <label className="pb-2">Quoted Price</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Quoted Price"
                        name="quoted_price"
                        onChange={(e) => handleChangeLevel1(e)}
                        value={inputList.quoted_price}
                      />
                      <span className="text-danger">{errorList['quoted_price']}</span>
                    </div>
                  </div>

                  {/* Customer Address */}
                  <div className="col-lg-4 mb-3">
                    <div className="form-group">
                      <label className="pb-2">Customer Reference</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Customer Reference"
                        name="customer_reference"
                        onChange={(e) => handleChangeLevel1(e)}
                        value={inputList.customer_reference}
                      />
                      <span className="text-danger">{errorList['customer_reference']}</span>
                    </div>
                  </div>

                  {/* Driver Note */}
                  <div className="col-lg-12 mb-3">
                    <div className="form-group">
                      <label className="pb-2">Driver Notes</label>
                      <textarea
                        className="form-control"
                        type="text"
                        placeholder="Driver Notes"
                        name="driver_note"
                        onChange={(e) => handleChangeLevel1(e)}
                        value={inputList.driver_note}
                      ></textarea>
                      <span className="text-danger">{errorList['driver_note']}</span>
                    </div>
                  </div>

                  {/* Address details table */}
                  <div className="col-lg-12 mb-3">
                    <table id="example" className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th>Customer Address</th>
                          <th>Delivery Zone</th>
                          <th>Address Contact Number</th>
                          <th>Address Contact Name</th>
                          <th>Address Note</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          {addressSelect.address ? (
                            <>
                              <td>{addressSelect?.address}</td>
                              <td>{addressSelect?.delivery_zones?.name}</td>
                              <td>
                                {addressSelect?.resident_contact}
                                <br></br>
                                {addressSelect?.resident_contact_two}
                              </td>
                              <td>
                                {addressSelect?.resident_name}
                                <br></br>
                                {addressSelect?.resident_name_two}
                              </td>
                              <td>{addressSelect?.location_note}</td>
                            </>
                          ) : (
                            <>
                              <td colSpan="7">Please select the address</td>
                            </>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {/* Admin Note */}
                  <div className="col-lg-12 mb-3">
                    <div className="form-group">
                      <label className="pb-2">Admin Notes</label>
                      <textarea
                        className="form-control"
                        type="text"
                        placeholder="Admin Notes"
                        name="admin_note"
                        onChange={(e) => handleChangeLevel1(e)}
                        value={inputList.admin_note}
                      ></textarea>
                      <span className="text-danger">{errorList['admin_note']}</span>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  {/* Order Information */}
                  <h5>Order information</h5>
                  <div className="row">
                    <div className="row">
                      {/* Created By */}
                      <div className="col-lg-4 mb-3">
                        <div className="form-group">
                          <label className="pb-2">Created by</label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="User Created By"
                            name="user_name"
                            value={inputList.user_name}
                            disabled
                          />
                          <input
                            className="form-control"
                            type="text"
                            placeholder="User Created By"
                            name="created_by"
                            onChange={(e) => handleChangeLevel1(e)}
                            value={inputList.created_by}
                            disabled
                            hidden
                          />
                          <span className="text-danger">{errorList['created_by']}</span>
                        </div>
                      </div>

                      {/* Created By */}
                      <div className="col-lg-4 mb-3">
                        <div className="form-group">
                          <label className="pb-2">
                            Order type <span className="text-danger">*</span>
                          </label>
                          <CFormSelect
                            onChange={(e) => handleChangeLevel1XeroCode(e)}
                            value={inputList.order_type}
                            aria-label="Default select example"
                            name="order_type"
                            disabled={priceLoaded}
                          >
                            <option value={0}>-- select --</option>
                            {orderType?.map((item, i) => {
                              return (
                                <option value={item.id} key={i}>
                                  {item.name}
                                </option>
                              )
                            })}
                          </CFormSelect>
                          <span className="text-danger">{errorList['order_type']}</span>
                        </div>
                      </div>

                      {/* Created By */}
                      <div className="col-lg-4 mb-3">
                        <div className="form-group">
                          <label className="pb-2">Sub Order type</label>
                          <CFormSelect
                            onChange={(e) => handleChangeLevel1(e)}
                            value={inputList.sub_order_type}
                            aria-label="Default select example"
                            name="sub_order_type"
                          >
                            <option value={0}>-- select --</option>
                            {subOrderType?.map((item, i) => {
                              return (
                                <option value={item.id} key={i}>
                                  {item.name}
                                </option>
                              )
                            })}
                          </CFormSelect>
                          <span className="text-danger">{errorList['sub_order_type']}</span>
                        </div>
                      </div>

                      {/* Delivery Zone */}
                      <div className="col-lg-4 mb-3">
                        <div className="form-group">
                          <label className="pb-2">
                            Order Delivery Zone <span className="text-danger">*</span>
                          </label>
                          <CFormSelect
                            onChange={(e) => handleChangeLevel1XeroCode(e)}
                            value={inputList.order_delivery_zone}
                            aria-label="Default select example"
                            name="order_delivery_zone"
                            disabled={priceLoaded}
                          >
                            <option value={0}>-- select --</option>

                            {orderDeliveryZone?.map((item, i) => {
                              return (
                                <option value={item.id} key={i}>
                                  {item.name}
                                </option>
                              )
                            })}
                          </CFormSelect>
                          <span className="text-danger">{errorList['order_delivery_zone']}</span>
                        </div>
                      </div>

                      {/* SKU location */}
                      <div className="col-lg-4 mb-3">
                        <div className="form-group">
                          <label className="pb-2">SKU Location</label>

                          <CFormSelect
                            onChange={(e) => handleChangeLevel1XeroCode(e)}
                            value={inputList.sku_location}
                            aria-label="sku_location"
                            name="sku_location"
                            disabled={priceLoaded}
                          >
                            <option value={0}>-- select --</option>

                            {skuLocation.map((item, i) => (
                              <option value={item.id} key={i}>
                                {item.name}
                              </option>
                            ))}
                          </CFormSelect>
                          <span className="text-danger">{errorList['sku_location']}</span>
                        </div>
                      </div>

                      {/* Created By */}
                      <div className="col-lg-4 mb-3">
                        <div className="form-group">
                          <label className="pb-2">Agent</label>
                          <CFormSelect
                            onChange={(e) => handleChangeLevel1(e)}
                            aria-label="Default select example"
                            name="agent_id"
                            value={inputList.agent_id}
                          >
                            <option value={0}>-- select --</option>
                            {agents?.map((item, i) => {
                              return (
                                <option value={item.id} key={i}>
                                  {item.name}
                                </option>
                              )
                            })}
                          </CFormSelect>
                          <span className="text-danger">{errorList['agent_id']}</span>
                        </div>
                      </div>
                      {/* Created By */}
                      <div className="col-lg-4 mb-3">
                        <div className="form-group">
                          <label className="pb-2">Machine</label>
                          <CFormSelect
                            onChange={(e) => handleChangeLevel1XeroCode(e)}
                            aria-label="Default select example"
                            name="machine_id"
                            value={inputList.machine_id}
                            disabled={priceLoaded}
                          >
                            <option value="">-- select --</option>
                            {machines?.map((item, i) => {
                              return (
                                <option value={item.id} key={i}>
                                  {item.name}
                                </option>
                              )
                            })}
                          </CFormSelect>
                          <span className="text-danger">{errorList['machine_id']}</span>
                        </div>
                      </div>

                      {/* Crops */}
                      <div className="col-lg-4 mb-3">
                        <div className="form-group">
                          <label className="pb-2">Crop</label>
                          <CFormSelect
                            onChange={(e) => handleChangeLevel1(e)}
                            aria-label="Default select example"
                            name="crop_id"
                            value={inputList.crop_id}
                          >
                            <option value="">-- select --</option>
                            {crops?.map((item, i) => {
                              return (
                                <option value={item.id} key={i}>
                                  {item.name}
                                </option>
                              )
                            })}
                          </CFormSelect>
                          <span className="text-danger">{errorList['machine_id']}</span>
                        </div>
                      </div>

                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <label className="pb-2">Operators</label>

                          {inputList.operators.map((items, i) => (
                            <div className="row" key={i}>
                              <div className="col-lg-6 mb-2 mt-2 pt-2">
                                <CFormSelect
                                  onChange={(e) => handleChangeLevel2(e, i, 'operators')}
                                  aria-label="Default select example"
                                  name="operators_id"
                                  value={items.operators_id}
                                >
                                  <option value={0}>-- select --</option>
                                  {operator?.map((item, i) => {
                                    return (
                                      <option value={item.id} key={i}>
                                        {item.fname} {item.lname}
                                      </option>
                                    )
                                  })}
                                </CFormSelect>
                                <span className="text-danger">
                                  {errorList['operators.' + i + '.operators_id']}
                                </span>
                              </div>
                              {inputList.operators.length - 1 === i && (
                                <div className="col-lg-2 mb-2 mt-2 pt-2">
                                  <button
                                    className="btn btn-success"
                                    onClick={(e) => handleAddOperators(e)}
                                  >
                                    Add Operators
                                  </button>
                                </div>
                              )}

                              {inputList.operators.length !== 1 && (
                                <div className="col-lg-3 mb-2 mt-2 pt-2">
                                  <button
                                    className="btn btn-danger"
                                    onClick={(e) => handleRemoveOperators(e, i)}
                                  >
                                    Remove Operators
                                  </button>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="pb-2">Order Note</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Order Note"
                          name="order_note"
                          onChange={(e) => handleChangeLevel1(e)}
                          value={inputList.order_note}
                        />
                        <span className="text-danger">{errorList['order_note']}</span>
                      </div>
                    </div>
                  </div>

                  <hr />
                </div>
                {/* Product Information */}
                <span className="text-danger">{errorList['product_information']}</span>
                <div className="d-flex justify-content-between">
                  <h4>
                    Product Information -{' '}
                    <span className="text-muted">
                      ( {orderTypeName ? orderTypeName : 'Please select the order type'} )
                    </span>
                  </h4>
                  {/* <button className="btn btn-success" onClick={addProductRow}>
                    Add Product
                  </button> */}
                </div>
                <div className="row">
                  {/* Spread Charge Rate */}
                  <div className="col-lg-3 mb-3">
                    <div className="form-group">
                      <label className="pb-2">Custom Spread Rate</label>
                      <input
                        className="form-control"
                        type="text"
                        name="custom_spread_rate"
                        onChange={(e) => handleChangeLevel1(e)}
                        value={inputList.custom_spread_rate}
                        placeholder="Custom Spread Rate"
                      />
                      <span className="text-danger">{errorList['custom_spread_rate']}</span>
                    </div>
                  </div>
                  {/* Tons Ordered */}
                  <div id="tons_ordered" className="col-lg-3 mb-3">
                    <div className="form-group">
                      <label className="pb-2">Tons Ordered</label>
                      <input
                        className="form-control"
                        type="number"
                        min="0"
                        step="any"
                        name="tons_ordered"
                        onKeyUp={(e) => percentatgeCalc(e)}
                        onChange={(e) => handleChangeLevel1(e)}
                        value={inputList.tons_ordered}
                        onBlur={(e) => handleBlurDecimalLevel1(e)}
                        placeholder="Tons Ordered"
                        disabled
                      />
                      <span className="text-danger">{errorList['tons_ordered']}</span>
                    </div>
                  </div>
                  {/* Tons Actual */}
                  <div className="col-lg-3 mb-3">
                    <div className="form-group">
                      <label className="pb-2">Tons Actual</label>
                      <input
                        className="form-control"
                        type="text"
                        name="tons_actual"
                        onChange={(e) => handleChangeLevel1(e)}
                        value={Number(inputList.tons_actual).toFixed(3)}
                        placeholder="Tons Actual"
                        disabled
                      />
                      <span className="text-danger">{errorList['tons_actual']}</span>
                    </div>
                  </div>
                </div>
                {inputList.product_information.map((items, i) => (
                  <div key={i}>
                    <hr></hr>
                    <div className="row">
                      {/* Product Mix */}
                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <label className="pb-2">Product Code</label>

                          <div className="row">
                            <div className="col-lg-4 pe-0">
                              <Select
                                onChange={(e) => handleChangeProductMix(e, i)}
                                aria-label="Default select example"
                                name="product_id"
                                value={items.product_mix_id}
                                options={productMixList}
                                ref={ref}
                              />

                              {/* <CFormSelect
                                onChange={(e) => handleChangeProductMix(e, i)}
                                aria-label="Default select example"
                                name="product_id"
                                value={items.product_mix_id}
                                ref={ref}
                              >
                                <option>-- Select --</option>
                                {productMixList.map((item, i) => (
                                  <option value={item.id} key={i}>
                                    {item.product_docket}
                                  </option>
                                ))}
                              </CFormSelect> */}
                            </div>
                            <div className="col ps-0">
                              <button
                                className="btn btn-success text-nowrap ms-3"
                                onClick={(e) => handleAddNewProduct(e, i)}
                              >
                                Add Product
                              </button>
                            </div>
                          </div>
                          <span className="text-danger">
                            {errorList['product_information.' + i + '.product_mix_id']}
                          </span>
                        </div>
                      </div>

                      {/* Product table */}
                      <div className="col-lg-12 mb-3">
                        <table id="example" className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th>#ID</th>
                              <th>Product Name</th>
                              <th
                                id="quantity"
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => enableQuantity(e)}
                              >
                                Quantity
                              </th>

                              {inputList.product_information[i].order_list_products.length > 1 && (
                                <th
                                  id="mix_percentage"
                                  style={{ cursor: 'pointer' }}
                                  onClick={(e) => enableMix(e)}
                                >
                                  Mix %
                                </th>
                              )}
                              <th>Quantity Type</th>
                              {inputList.product_information[i].order_list_products.length <= 1 && (
                                <th>Total Quantity</th>
                              )}
                              <th>Xero Code</th>
                              <th>Unit Price</th>
                              <th>
                                Spread Charge Rate
                                <span className="text-danger">
                                  {inputList.has_spread_charge_rate === '0' ? '' : '*'}
                                </span>
                              </th>

                              <th>Action Button</th>
                            </tr>
                          </thead>

                          <tbody>
                            {items.order_list_products?.map((data, index) => (
                              <tr key={index}>
                                <td>{data.product_mix_id}</td>
                                <td>{data.product_name}</td>
                                <td>
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      type="number"
                                      name="quantity"
                                      min="0"
                                      step="any"
                                      onBlur={(e) =>
                                        quantityTotalBlur(
                                          e,
                                          i,
                                          index,
                                          'product_information',
                                          'order_list_products',
                                        )
                                      }
                                      onChange={(e) =>
                                        handleChangeLevel3(
                                          e,
                                          i,
                                          index,
                                          'product_information',
                                          'order_list_products',
                                        )
                                      }
                                      onKeyUp={(e) =>
                                        quantityTotal(
                                          e,
                                          i,
                                          index,
                                          'product_information',
                                          'order_list_products',
                                        )
                                      }
                                      value={data.quantity}
                                      placeholder="Quantity"
                                    />
                                    <span className="text-danger">
                                      {errorList['product_information.' + i + '.quantity']}
                                    </span>
                                  </div>
                                </td>
                                {inputList.product_information[i].order_list_products.length >
                                  1 && (
                                  <td>
                                    <div className="form-group">
                                      <input
                                        className="form-control"
                                        type="number"
                                        min="0"
                                        name="mix_percentage"
                                        onBlur={(e) =>
                                          percentatgeCalcBlur(
                                            e,
                                            i,
                                            index,
                                            'product_information',
                                            'order_list_products',
                                          )
                                        }
                                        onChange={(e) =>
                                          handleChangeLevel3(
                                            e,
                                            i,
                                            index,
                                            'product_information',
                                            'order_list_products',
                                          )
                                        }
                                        onKeyUp={(e) => percentatgeCalc(e, i, index)}
                                        value={data.mix_percentage}
                                        placeholder="%"
                                        step="any"
                                        disabled
                                      />
                                      <span className="text-danger">
                                        {errorList['product_information.' + i + '.quantity']}
                                      </span>
                                    </div>
                                  </td>
                                )}
                                <td className="mb-3">
                                  <CFormSelect
                                    onChange={(e) =>
                                      handleChangeLevel3XeroCode(
                                        e,
                                        i,
                                        index,
                                        'product_information',
                                        'order_list_products',
                                      )
                                    }
                                    aria-label="Default select example"
                                    name="quantity_type"
                                    value={
                                      inputList.product_information[i].order_list_products.length >
                                      1
                                        ? 6
                                        : data.quantity_type
                                    }
                                    disabled={
                                      inputList.product_information[i].order_list_products.length >
                                        1 || priceLoaded
                                    }
                                  >
                                    <option value={0}>-- select --</option>

                                    {quantityType.map((item, i) => (
                                      <option value={item.id} key={i}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </CFormSelect>

                                  <span className="text-danger">
                                    {errorList['product_information.' + i + '.quantity_type']}
                                  </span>
                                </td>
                                {inputList.product_information[i].order_list_products.length <=
                                  1 && (
                                  <td>
                                    <CFormSelect
                                      onChange={(e) =>
                                        handleChangeLevel3(
                                          e,
                                          i,
                                          index,
                                          'product_information',
                                          'order_list_products',
                                        )
                                      }
                                      aria-label="Default select example"
                                      name="quantity_type"
                                      value={
                                        inputList.product_information[i].order_list_products
                                          .length > 1
                                          ? 6
                                          : data.quantity_type
                                      }
                                      disabled
                                    >
                                      <option value={0}>-- select --</option>

                                      {quantityType.map((item, i) => (
                                        <option value={item.id} key={i}>
                                          {Number(data.quantity * item.quantity_value).toFixed(3)}
                                        </option>
                                      ))}
                                    </CFormSelect>
                                  </td>
                                )}

                                <td className="mb-3">
                                  <div className="form-group">
                                    <input
                                      disabled
                                      className={
                                        data.xero_code === 'N/A'
                                          ? 'form-control text-danger'
                                          : data.unit_price === 'N/A'
                                          ? 'form-control text-danger border-danger'
                                          : 'form-control'
                                      }
                                      type="text"
                                      name="xero_code"
                                      onChange={(e) =>
                                        handleChangeLevel3(
                                          e,
                                          i,
                                          index,
                                          'product_information',
                                          'order_list_products',
                                        )
                                      }
                                      value={xeroCodeGenerator(
                                        skuLocation,
                                        orderType,
                                        machines,
                                        inputList?.product_information[i]?.order_list_products[
                                          index
                                        ]?.product_name,
                                        spreadchargerates,
                                        inputList?.product_information[i]?.order_list_products[0]
                                          ?.spread_charge_rate,
                                        quantityType,
                                        inputList.product_information[i].order_list_products
                                          .length > 1
                                          ? 6
                                          : inputList?.product_information[i]?.order_list_products[
                                              index
                                            ]?.quantity_type,
                                        inputList,
                                      )}
                                      placeholder="Xero Code"
                                    />
                                    <span className="text-danger">
                                      {errorList['product_information.' + i + '.xero_code']}
                                    </span>
                                  </div>
                                </td>
                                <td className="mb-3">
                                  <div className="form-group">
                                    <input
                                      className={
                                        data.unit_price === 'N/A'
                                          ? 'form-control text-danger'
                                          : 'form-control'
                                      }
                                      type="text"
                                      name="unit_price"
                                      onChange={(e) => {
                                        handleChangeLevel3(
                                          e,
                                          i,
                                          index,
                                          'product_information',
                                          'order_list_products',
                                        )
                                        handleChangeLevel4(
                                          e,
                                          i,
                                          index,
                                          'product_information',
                                          'order_list_products',
                                        )
                                      }}
                                      value={data.unit_price}
                                      /*  value={
                                        inputList.product_information[0].order_list_products
                                          .length > 1
                                          ? (
                                              (parseFloat(data.mix_percentage) / 100) *
                                                parseFloat(data.unit_price) +
                                              parseFloat(data.unit_price)
                                            ).toFixed(3)
                                          : parseFloat(data.unit_price).toFixed(3)
                                      } */
                                      placeholder="Unit Price"
                                      disabled={priceLoaded}
                                    />
                                    <span className="text-danger">
                                      {errorList['product_information.' + i + '.unit_price']}
                                    </span>
                                  </div>
                                </td>
                                <td className="mb-3">
                                  <div className="form-group">
                                    {inputList.has_spread_charge_rate === '0' ? (
                                      <label className="pb-2">N / A</label>
                                    ) : (
                                      <CFormSelect
                                        onChange={(e) =>
                                          handleChangeLevel3XeroCode(
                                            e,
                                            i,
                                            0,
                                            'product_information',
                                            'order_list_products',
                                          )
                                        }
                                        aria-label="Default select example"
                                        name="spread_charge_rate"
                                        value={
                                          inputList?.product_information[i]?.order_list_products[0]
                                            ?.spread_charge_rate
                                        }
                                        disabled={priceLoaded}
                                      >
                                        <option value={0}>-- select --</option>

                                        {spreadchargerates.map((item, i) => (
                                          <option value={item.id} key={i}>
                                            {item.name}
                                          </option>
                                        ))}
                                      </CFormSelect>
                                    )}

                                    <span className="text-danger">
                                      {
                                        errorList[
                                          'product_information.' + i + '.spread_charge_rate'
                                        ]
                                      }
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <button
                                    onClick={(e) =>
                                      removeLevel3(
                                        e,
                                        i,
                                        index,
                                        'product_information',
                                        'order_list_products',
                                      )
                                    }
                                    className="btn btn-danger"
                                  >
                                    Remove
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {inputList.product_information[0].order_list_products.length > 1 ? (
                          <>
                            {' '}
                            <div className="row">
                              <div className="col-lg-2">
                                <div className="form-group">
                                  <label className="pb-2">Mix SKU</label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="Mix SKU"
                                    /* onChange={(e) =>
                                  handleChangeLevel3(
                                    e,
                                    i,
                                    docketIndex,
                                    'product_information',
                                    'docket_numbers',
                                  )
                                } */
                                    value={mixCodeValue}
                                    placeholder="Mix SKU"
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-lg-2">
                                <div className="form-group">
                                  <label className="pb-2">Mix Price</label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="Mix Code"
                                    /* onChange={(e) =>
                                  handleChangeLevel3(
                                    e,
                                    i,
                                    docketIndex,
                                    'product_information',
                                    'docket_numbers',
                                  )
                                } */
                                    value={mixSkuValue}
                                    placeholder="Mix Price"
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="d-flex mb-3">
                        <h4 className="me-3">Docket List</h4>

                        <button className="btn btn-success" onClick={(e) => handleAddDocket(e, i)}>
                          Add Docket List
                        </button>
                      </div>
                      {/* Docket Number */}
                      {items.docket_numbers.map((items, docketIndex) => (
                        <div key={docketIndex} className="row">
                          <div className="col-lg-2">
                            <div className="form-group">
                              <label className="pb-2">Docket Number</label>
                              <input
                                className="form-control"
                                type="text"
                                name="docket_number"
                                onChange={(e) =>
                                  handleChangeLevel3(
                                    e,
                                    i,
                                    docketIndex,
                                    'product_information',
                                    'docket_numbers',
                                  )
                                }
                                value={items.docket_number}
                                placeholder="Docket Number"
                              />
                              <span className="text-danger">
                                {
                                  errorList[
                                    'product_information.' +
                                      i +
                                      '.docket_numbers.' +
                                      docketIndex +
                                      '.docket_number'
                                  ]
                                }
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-group">
                              <label className="pb-2">Docket Date</label>
                              <input
                                className="form-control"
                                type="date"
                                step="any"
                                name="docket_date"
                                onChange={(e) =>
                                  handleChangeLevel3(
                                    e,
                                    i,
                                    docketIndex,
                                    'product_information',
                                    'docket_numbers',
                                  )
                                }
                                value={items.docket_date}
                              />
                              <span className="text-danger">
                                {
                                  errorList[
                                    'product_information.' +
                                      i +
                                      '.docket_numbers.' +
                                      docketIndex +
                                      '.docket_date'
                                  ]
                                }
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-group">
                              <label className="pb-2">Location</label>

                              <CFormSelect
                                onChange={(e) =>
                                  handleChangeLevel3(
                                    e,
                                    i,
                                    docketIndex,
                                    'product_information',
                                    'docket_numbers',
                                  )
                                }
                                value={items.sku_location}
                                aria-label="sku_location"
                                name="sku_location"
                              >
                                <option value={0}>-- select --</option>

                                {skuLocation.map((item, i) => (
                                  <option value={item.id} key={i}>
                                    {item.name}
                                  </option>
                                ))}
                              </CFormSelect>
                              <span className="text-danger">
                                {
                                  errorList[
                                    'product_information.' +
                                      i +
                                      '.docket_numbers.' +
                                      docketIndex +
                                      '.sku_location'
                                  ]
                                }
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-group">
                              <label className="pb-2">Weight</label>
                              <input
                                className="form-control"
                                type="number"
                                step="any"
                                name="weight"
                                onChange={(e) =>
                                  handleChangeLevel3(
                                    e,
                                    i,
                                    docketIndex,
                                    'product_information',
                                    'docket_numbers',
                                  )
                                }
                                value={items.weight}
                                placeholder="Weight"
                              />
                              <span className="text-danger">
                                {
                                  errorList[
                                    'product_information.' +
                                      i +
                                      '.docket_numbers.' +
                                      docketIndex +
                                      '.weight'
                                  ]
                                }
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-group">
                              {/* <FileInput
                                name="imageURL"
                                onChange={(e) => handleImage(e, docketIndex, i)}
                                value={items.imageURL}
                              /> */}

                              <label className="pb-2">File Upload </label>
                              <br />

                              <input
                                className="form-control"
                                onChange={(e) => handleImage(e, docketIndex, i)}
                                type="file"
                                name="imageURL"
                              />
                              <span className="text-danger">{errorList.image}</span>
                              <span className="text-danger">
                                {
                                  errorList[
                                    'product_information.' +
                                      i +
                                      '.docket_numbers.' +
                                      docketIndex +
                                      '.imageURL'
                                  ]
                                }
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-group">
                              {/* <FileInput
                                name="imageURL"
                                onChange={(e) => handleImage(e, docketIndex, i)}
                                value={items.imageURL}
                              /> */}

                              <input
                                className="form-control"
                                onChange={(e) => handleImage(e, docketIndex, i)}
                                type="text"
                                name="imageName"
                                value={items.imageName}
                                hidden
                              />
                              {items.imageURL !== '' && items.imageURL !== null && (
                                <label className="pb-2">
                                  Preview Current File:
                                  <br></br>
                                  <a href={items.imageURL} rel="noreferrer" target="_blank">
                                    {items.imageName}
                                  </a>
                                </label>
                              )}
                              <br />
                            </div>
                          </div>
                          <div className="col-lg-12 mb-4">
                            <div className="">
                              {inputList.product_information[i].docket_numbers.length - 1 ===
                                docketIndex && (
                                <button
                                  className="btn btn-success me-3"
                                  onClick={(e) => handleAddDocket(e, i)}
                                >
                                  Add Docket
                                </button>
                              )}

                              <button
                                className="btn btn-danger"
                                onClick={(e) => handleRemoveDocket(e, docketIndex, i)}
                              >
                                Remove Docket
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}

                      {/* <div className="d-flex justify-content-between  mt-4">
                        <button className="btn btn-danger" onClick={(e) => removeRoductRow(e, i)}>
                          Remove Product
                        </button>
                        {inputList.product_information.length - 1 === i && (
                          <button className="btn btn-success" onClick={addProductRow}>
                            Add Product
                          </button>
                        )}
                      </div> */}
                    </div>
                  </div>
                ))}
                <hr />
                <div className="d-flex justify-content-between mb-4">
                  <h4>Other Invoice Line Items</h4>
                  <button
                    style={{ marginRight: '10px' }}
                    className="btn btn-success"
                    onClick={(e) => addLineItems(e)}
                  >
                    Add Line Items
                  </button>
                </div>
                <table id="example" className="table table-bordered table-striped">
                  {inputList.other_line_items.length >= 1 && (
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Description</th>
                        <th>Quantity</th>
                        <th>Amount</th>
                        <th>Total</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                  )}

                  <tbody>
                    {inputList?.other_line_items?.map((item, i) => (
                      <tr key={i}>
                        <td>
                          <CFormSelect
                            aria-label="Default select example"
                            name="type"
                            onChange={(e) => handleChangeOtherLineItems(e, i)}
                            value={item.type}
                          >
                            <option>-- select --</option>
                            {lineItems.map((item, i) => (
                              <option value={item.id} key={i}>
                                {item.name}
                              </option>
                            ))}
                          </CFormSelect>
                          <span className="text-danger">
                            {errorList['other_line_items.' + i + '.type']}
                          </span>
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="text"
                            name="description"
                            placeholder="Description"
                            onChange={(e) => handleChangeOtherLineItems(e, i)}
                            value={item.description}
                          />
                          <span className="text-danger">
                            {errorList['other_line_items.' + i + '.description']}
                          </span>
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="number"
                            min="0"
                            step="any"
                            name="quantity"
                            onChange={(e) => handleChangeOtherLineItems(e, i)}
                            placeholder="Quantity"
                            value={item.quantity}
                            onBlur={(e) => handleBlurDecimalLevel2(e, i, 'other_line_items')}
                          />
                          <span className="text-danger">
                            {errorList['other_line_items.' + i + '.quantity']}
                          </span>
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="number"
                            step="any"
                            name="amount"
                            onChange={(e) => handleChangeOtherLineItems(e, i)}
                            onBlur={(e) => handleBlurDecimalLevel2(e, i, 'other_line_items')}
                            value={item.amount}
                            placeholder="Amount"
                          />
                          <span className="text-danger">
                            {errorList['other_line_items.' + i + '.amount']}
                          </span>
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="number"
                            min="0"
                            step="any"
                            name="total"
                            placeholder="Total"
                            value={Number(Number(item.quantity) * Number(item.amount)).toFixed(3)}
                            disabled
                          />
                        </td>
                        <td>
                          <div className="d-flex">
                            {inputList.other_line_items.length - 1 === i && (
                              <button
                                style={{ marginRight: '10px' }}
                                className="btn btn-success"
                                onClick={(e) => addLineItems(e)}
                              >
                                Add
                              </button>
                            )}
                            <button
                              className="btn btn-danger"
                              onClick={(e) => removeLineItems(e, i)}
                            >
                              Remove
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="mt-4">
                  <button type="submit" className="btn btn-primary">
                    {id ? 'Update' : 'Save'}
                  </button>
                  <button
                    type="submit"
                    onClick={(e) => saveAndClose(e)}
                    className="btn btn-primary ms-3"
                  >
                    {id ? 'Update And Close' : 'Save And Close'}
                  </button>
                  {id != null && (
                    <button onClick={(e) => duplicateOrder(e)} className="btn btn-info ms-3">
                      Duplicate Entry
                    </button>
                  )}
                </div>
                {/* <pre>{JSON.stringify(inputList, null, 2)}</pre> */}
                {/*
                <pre>{JSON.stringify(errorList, null, 2)}</pre> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Create
