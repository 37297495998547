import React from 'react'

const Card = ({ data, children }) => {
  return (
    <div className="card">
      <div className="card-header">
        {data.map((item) => (
          <h5 key={item.length} className="card-title mt-2">
            {item.addorupdate} {item.title}
          </h5>
        ))}
      </div>
      <div className="card-body">{children}</div>
    </div>
  )
}

export default Card
