import React, { useContext } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

import {
  CAvatar,
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import { cilCreditCard } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import avatar8 from './../../assets/images/avatars/3.jpg'
import { UserContext, Usercntxt } from './../../context/UserContext'

const AppHeaderDropdown = () => {
  const history = useHistory()
  const user = useContext(Usercntxt)

  const logoutSubmit = (e) => {
    e.preventDefault()

    axios.post('/api/logout').then((res) => {
      if (res.data.status === 200) {
        localStorage.removeItem('auth_token')
        localStorage.removeItem('auth_name')
        /* swal("Success", res.data.message, "success"); */
        history.go(0)
      }
    })
  }

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <div className="me-2 d-inline">
          {user.userState.name} ({user.userState.role})
        </div>
        <CAvatar src={avatar8} size="md" />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem href="/profile">
          <CIcon icon={cilCreditCard} className="me-2" />
          Profile
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem href="#" onClick={logoutSubmit}>
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
