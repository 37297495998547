import { Agents } from './routes'
import axios from 'axios'

export const getAgents = () => {
  return axios.get(`${Agents}`)
}

export const getAgentSingle = (id) => {
  return axios.get(`${Agents}/${id}/edit`)
}

export const createAgents = (data) => {
  return axios.post(`${Agents}`, data)
}

export const updateAgents = (id, data) => {
  return axios.put(`${Agents}/${id}`, data)
}

export const deleteAgents = (id) => {
  return axios.delete(`${Agents}/${id}`)
}
