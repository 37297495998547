import { Quantity } from './routes'
import axios from 'axios'

export const getQuantity = () => {
  return axios.get(`${Quantity}`)
}

export const getQuantitySingle = (id) => {
  return axios.get(`${Quantity}/${id}/edit`)
}

export const createQuantity = (data) => {
  return axios.post(`${Quantity}`, data)
}

export const updateQuantity = (id, data) => {
  return axios.put(`${Quantity}/${id}`, data)
}

export const deleteQuantity = (id) => {
  return axios.delete(`${Quantity}/${id}`)
}
