import { SpreadChargeRate } from './routes'
import axios from 'axios'

export const getSpreadChargeRate = () => {
  return axios.get(`${SpreadChargeRate}`)
}

export const getSpreadChargeRateSingle = (id) => {
  return axios.get(`${SpreadChargeRate}/${id}/edit`)
}

export const createSpreadChargeRate = (data) => {
  return axios.post(`${SpreadChargeRate}`, data)
}

export const updateSpreadChargeRate = (id, data) => {
  return axios.put(`${SpreadChargeRate}/${id}`, data)
}

export const deleteSpreadChargeRate = (id) => {
  return axios.delete(`${SpreadChargeRate}/${id}`)
}
