import React, { useState, useEffect, useContext } from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import DefaultLayout from './layout/DefaultLayout'
import axios from 'axios'
import Preloader from 'src/views/preloader/Preloader'
import { UserContext, Usercntxt } from './context/UserContext'
import swal from 'sweetalert'
import { debounce } from 'lodash'

function AdminPrivateRoute({ ...rest }) {
  const history = useHistory()
  const [Authenticated, setAuthenticated] = useState(false)
  const [loading, setLoading] = useState(true)

  const user = useContext(Usercntxt)

  function safeRemoveItem(key) {
    try {
      localStorage.removeItem(key)
    } catch (error) {
      if (error.name === 'SecurityError') {
        console.error('SecurityError while removing item from localStorage:', error)
      } else {
        console.error('Error removing item from localStorage:', error)
      }
    }
  }

  useEffect(() => {
    const debouncedFetchRolePermissions = debounce(async () => {
      try {
        const res = await axios.get('/api/role_permissions', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
          },
        })
        if (res.data.status === 200) {
          user.setUser(res.data.user)
          setAuthenticated(true)
          setLoading(false)
        } else {
          setAuthenticated(false)
          setLoading(false)
          user.setUser(null)
          localStorage.removeItem('auth_token')
          localStorage.removeItem('auth_name')
        }
      } catch (err) {
        console.log('errors', err)
        if (err.response && err.response.status === 401) {
          console.log('true')
          // Handle unauthorized error specifically
          localStorage.removeItem('auth_token')
          localStorage.removeItem('auth_name')
        }
        setAuthenticated(false)
        setLoading(false)
        user.setUser(null)
      }
    }, 10)

    debouncedFetchRolePermissions()

    // Cleanup function to cancel the debounced call if the component unmounts
    return () => {
      debouncedFetchRolePermissions.cancel()
      swal('Error', 'Unauthorized Access', 'error')
      window.location.href = '/login'
    }
  }, [])

  if (loading) {
    return <Preloader />
  }

  return (
    <Route
      {...rest}
      render={({ props, location }) =>
        Authenticated ? (
          <DefaultLayout {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )
      }
    />
  )
}

export default AdminPrivateRoute
