import { Orders } from './routes'
import axios from 'axios'

export const getOrders = () => {
  return axios.get(`${Orders}`)
}

export const getOrderSingle = (id) => {
  return axios.get(`${Orders}/${id}/edit`)
}

export const createOrders = (data) => {
  return axios.post(`${Orders}`, data)
}

export const updateOrders = (id, data) => {
  return axios.put(`${Orders}/${id}`, data)
}

export const deleteOrders = (id) => {
  return axios.delete(`${Orders}/${id}`)
}
