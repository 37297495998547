import { Operators } from './routes'
import axios from 'axios'

export const getOperators = () => {
  return axios.get(`${Operators}`)
}

export const getOperatorSingle = (id) => {
  return axios.get(`${Operators}/${id}/edit`)
}

export const createOperators = (data) => {
  return axios.post(`${Operators}`, data)
}

export const updateOperators = (id, data) => {
  return axios.put(`${Operators}/${id}`, data)
}

export const deleteOperators = (id) => {
  return axios.delete(`${Operators}/${id}`)
}
