import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import './scss/style.scss'
import axios from 'axios'
import AdminPrivateRoute from './AdminPrivateRoute'
import 'rsuite/dist/rsuite.min.css'

axios.defaults.baseURL = process.env.REACT_APP_URL
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.withCredentials = false

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('auth_token')
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  return (
    <>
      <Router>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route path="/login">
              {localStorage.getItem('auth_token') ? <Redirect to="/orders" /> : <Login />}
            </Route>

            <Route path="/register">
              {localStorage.getItem('auth_token') ? <Redirect to="/orders" /> : <Register />}
            </Route>

            <AdminPrivateRoute
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </Router>
    </>
  )
}

export default App
