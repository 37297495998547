import { Products } from './routes'
import axios from 'axios'

export const getProducts = () => {
  return axios.get(`${Products}`)
}

export const getProductSingle = (id) => {
  return axios.get(`${Products}/${id}/edit`)
}

export const createProducts = (data) => {
  return axios.post(`${Products}`, data)
}

export const updateProducts = (id, data) => {
  return axios.put(`${Products}/${id}`, data)
}

export const deleteProducts = (id) => {
  return axios.delete(`${Products}/${id}`)
}
