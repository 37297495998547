//Dashboard
/* import Dashboard from './views/dashboard/Dashboard' */

//Agents
import Agents from './views/agents/Agents'
import AgentsCreateOrUpdate from './views/agents/CreateOrUpdate'

//Line Items
import LineItems from './views/additional_line_items/LineItems'
import LineItemsCreateOrUpdate from './views/additional_line_items/CreateOrUpdate'

//Crops
import Crop from './views/crops/Crop'
import CropCreateUpdate from './views/crops/CreateOrUpdate'

//customer_terms
import CustomerTerms from './views/customer_terms/CustomerTerms'
import CustomerTermsCreateOrUpdate from './views/customer_terms/CreateOrUpdate'

//Delivery Zones
import DeliveryZones from './views/delivery_zones/DeliveryZones'
import DeliveryZonesCreateOrUpdate from './views/delivery_zones/CreateOrUpdate'

//Machines
import Machines from './views/machines/Machines'
import MachineCreateOrUpdate from './views/machines/CreateOrUpdate'

// Order Types
import OrderTypes from './views/order_types/OrderTypes'
import OrderTypesCreateOrUpdate from './views/order_types/CreateOrUpdate'

//Operators
import Operators from './views/operators/Operators'
import OperatorsCreateOrUpdate from './views/operators/CreateOrUpdate'

//Products
import Products from './views/products/Products'
import ProductCreateOrUpdate from './views/products/CreateOrUpdate'

//Product Mix
import ProductMix from './views/products/productmix/ProductMix'
import ProductMixCreateOrUpdate from './views/products/productmix/CreateOrUpdate'

//Quantity
import Quantity from './views/quantity/Quantity'
import QuantityCreateOrUpdate from './views/quantity/CreateOrUpdate'

//Spread Charge Rate
import ChargeRate from './views/spread_charge_rate/ChargeRate'
import ChargeRateCreateOrUpdate from './views/spread_charge_rate/CreateOrUpdate'

//SKU Location
import SkuLocation from './views/sku_location/SkuLocation'
import SkuLocationCreateOrUpdate from './views/sku_location/CreateOrUpdate'

// Sub Types
import OrderSubTypes from './views/sub_order_type/SubOrder'
import OrderSubTypesCreateOrUpdate from './views/sub_order_type/CreateOrUpdate'

//Orders
import OrdersOpen from './views/orders/Order'
import OrderCreateOrUpdate from './views/orders/CreateOrUpdate'
import OrdersOpenCreate from './views/orders/Create'
import OrdersOpenUpdate from './views/orders/Create'

//Customers
import Customers from './views/customers/Customers'
import CustomersCreate from './views/customers/Create'
import CustomersUpdate from './views/customers/Create'

//profile
import Profile from './views/profile/Profile'
import Xero from './views/xero/Xero'

//user
import User from './views/users/User'
import UserCreate from './views/users/Create'

//roles
import Roles from './views/users/roles/Roles'
import RolesCreate from './views/users/roles/Create'
import RolesUpdate from './views/users/roles/Create'

//permissions
import Permissions from './views/users/permissions/Permissions'
import PermissionsCreate from './views/users/permissions/Create'
import PermissionsUpdate from './views/users/permissions/Create'

import CustomReports from './views/reports/CustomReports'
import DailyReports from './views/reports/DailyReports'
import SpreadReport from './views/reports/SpreadReport'

const routes = [
  /* { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard }, */

  //users
  { path: '/users', name: 'Operators', component: User, exact: true, permission: 'access users' },
  { path: '/users/create', name: 'New', component: UserCreate, permission: 'access users' },
  {
    path: '/users/update/:id',
    name: 'Update',
    component: UserCreate,
    exact: true,
    permission: 'access users',
  },

  //roles
  { path: '/roles', name: 'Roles', component: Roles, exact: true, permission: 'access roles' },
  {
    path: '/roles/create',
    name: 'RolesCreate',
    component: RolesCreate,
    permission: 'access roles',
  },
  {
    path: '/roles/update/:id',
    name: 'RolesUpdate',
    component: RolesUpdate,
    exact: true,
    permission: 'access roles',
  },

  //Permissions
  {
    path: '/permissions',
    name: 'Permissions',
    component: Permissions,
    exact: true,
    permission: 'access roles',
  },
  {
    path: '/permissions/create',
    name: 'PermissionsCreate',
    component: PermissionsCreate,
    permission: 'access roles',
  },
  {
    path: '/permissions/update/:id',
    name: 'PermissionsUpdate',
    component: PermissionsUpdate,
    exact: true,
    permission: 'access roles',
  },

  //Agents
  {
    path: '/agents',
    name: 'Agents',
    component: Agents,
    exact: true,
    permission: 'access agents',
  },
  {
    path: '/agents/create',
    name: 'AgentsCreateOrUpdate',
    component: AgentsCreateOrUpdate,
    permission: 'access agents',
  },
  {
    path: '/agents/update/:id',
    name: 'AgentsCreateOrUpdate',
    component: AgentsCreateOrUpdate,
    permission: 'access agents',
  },

  //Line Items
  {
    path: '/line_items',
    name: 'LineItems',
    component: LineItems,
    exact: true,
    permission: 'access line items',
  },
  {
    path: '/line_items/create',
    name: 'LineItemsCreateOrUpdate',
    component: LineItemsCreateOrUpdate,
    permission: 'access line items',
  },
  {
    path: '/line_items/update/:id',
    name: 'LineItemsCreateOrUpdate',
    component: LineItemsCreateOrUpdate,
    permission: 'access line items',
  },

  //Crops
  {
    path: '/crops',
    name: 'Crops',
    component: Crop,
    exact: true,
    permission: 'access crops',
  },
  {
    path: '/crops/create',
    name: 'CropCreateUpdate',
    component: CropCreateUpdate,
    permission: 'access crops',
  },
  {
    path: '/crops/update/:id',
    name: 'CropCreateUpdate',
    component: CropCreateUpdate,
    permission: 'access crops',
  },

  //Customer Terms
  {
    path: '/customer_terms',
    name: 'CustomerTerms',
    component: CustomerTerms,
    exact: true,
    permission: 'access customer terms',
  },
  {
    path: '/customer_terms/create',
    name: 'CustomerTermsCreateOrUpdate',
    component: CustomerTermsCreateOrUpdate,
    permission: 'access customer terms',
  },
  {
    path: '/customer_terms/update/:id',
    name: 'CustomerTermsCreateOrUpdate',
    component: CustomerTermsCreateOrUpdate,
    permission: 'access customer terms',
  },

  //Delivery Zones
  {
    path: '/delivery_zones',
    name: 'DeliveryZones',
    component: DeliveryZones,
    exact: true,
    permission: 'access delivery zones',
  },
  {
    path: '/delivery_zones/create',
    name: 'DeliveryZonesCreateOrUpdate',
    component: DeliveryZonesCreateOrUpdate,
    permission: 'access delivery zones',
  },
  {
    path: '/delivery_zones/update/:id',
    name: 'DeliveryZonesCreateOrUpdate',
    component: DeliveryZonesCreateOrUpdate,
    permission: 'access delivery zones',
  },

  //Machines
  {
    path: '/machines',
    name: 'Machines',
    component: Machines,
    exact: true,
    permission: 'access machines',
  },
  {
    path: '/machines/create',
    name: 'MachineCreateOrUpdate',
    component: MachineCreateOrUpdate,
    permission: 'access machines',
  },
  {
    path: '/machines/update/:id',
    name: 'MachineCreateOrUpdate',
    component: MachineCreateOrUpdate,
    permission: 'access machines',
  },

  //Order Types
  {
    path: '/order_types',
    name: 'OrderType',
    component: OrderTypes,
    exact: true,
    permission: 'access order types',
  },
  {
    path: '/order_types/create',
    name: 'OrderTypesCreateOrUpdate',
    component: OrderTypesCreateOrUpdate,
    permission: 'access order types',
  },
  {
    path: '/order_types/update/:id',
    name: 'OrderTypesCreateOrUpdate',
    component: OrderTypesCreateOrUpdate,
    permission: 'access order types',
  },

  //Products
  {
    path: '/products',
    name: 'Products',
    component: Products,
    exact: true,
    permission: 'access products',
  },
  {
    path: '/products/create',
    name: 'ProductCreateOrUpdate',
    component: ProductCreateOrUpdate,
    permission: 'access products',
  },
  {
    path: '/products/update/:id',
    name: 'ProductCreateOrUpdate',
    component: ProductCreateOrUpdate,
    permission: 'access products',
  },

  //ProductMix
  {
    path: '/products/productmix',
    name: 'ProductMix',
    component: ProductMix,
    exact: true,
    permission: 'access products',
  },
  {
    path: '/products/productmix/create',
    name: 'ProductMixCreateOrUpdate',
    component: ProductMixCreateOrUpdate,
    permission: 'access products',
  },
  {
    path: '/products/productmix/update/:id',
    name: 'ProductMixCreateOrUpdate',
    component: ProductMixCreateOrUpdate,
    permission: 'access products',
  },

  //Operators
  {
    path: '/operators',
    name: 'Operators',
    component: Operators,
    exact: true,
    permission: 'access operators',
  },
  {
    path: '/operators/create',
    name: 'OperatorsCreateOrUpdate',
    component: OperatorsCreateOrUpdate,
    permission: 'access operators',
  },
  {
    path: '/operators/update/:id',
    name: 'OperatorsCreateOrUpdate',
    component: OperatorsCreateOrUpdate,
    exact: true,
    permission: 'access operators',
  },

  //Customers
  {
    path: '/customers',
    name: 'Customers',
    component: Customers,
    exact: true,
    permission: 'access customers',
  },
  {
    path: '/customers/create',
    name: 'CustomersCreate',
    component: CustomersCreate,
    permission: 'access customers',
  },
  {
    path: '/customers/update/:id',
    name: 'CustomersUpdate',
    component: CustomersUpdate,
    permission: 'access customers',
  },

  //Orders open
  {
    path: '/orders',
    name: 'OrdersOpen',
    component: OrdersOpen,
    exact: true,
    permission: 'access orders',
  },
  {
    path: '/orders/create',
    name: 'OrdersOpenCreate',
    component: OrdersOpenCreate,
    exact: true,
    permission: 'access orders',
  },
  {
    path: '/orders/update/:id',
    name: 'OrdersOpenUpdate',
    component: OrdersOpenUpdate,
    permission: 'access orders',
  },

  //OrderCreateOrUpdate

  //Quantity
  {
    path: '/quantity',
    name: 'Quantity',
    component: Quantity,
    exact: true,
    permission: 'access quantity',
  },
  {
    path: '/quantity/create',
    name: 'QuantityCreateOrUpdate',
    component: QuantityCreateOrUpdate,
    permission: 'access quantity',
  },
  {
    path: '/quantity/update/:id',
    name: 'QuantityCreateOrUpdate',
    component: QuantityCreateOrUpdate,
    permission: 'access quantity',
  },

  //Spread Charge Rate
  {
    path: '/spread_charge_rate',
    name: 'ChargeRate',
    component: ChargeRate,
    exact: true,
    permission: 'access spread charge rate',
  },
  {
    path: '/spread_charge_rate/create',
    name: 'ChargeRateCreateOrUpdate',
    component: ChargeRateCreateOrUpdate,
    permission: 'access spread charge rate',
  },
  {
    path: '/spread_charge_rate/update/:id',
    name: 'ChargeRateCreateOrUpdate',
    component: ChargeRateCreateOrUpdate,
    permission: 'access spread charge rate',
  },

  //SKU Location
  {
    path: '/sku_location',
    name: 'SkuLocation',
    component: SkuLocation,
    exact: true,
    permission: 'access sku location',
  },
  {
    path: '/sku_location/create',
    name: 'SkuLocationCreateOrUpdate',
    component: SkuLocationCreateOrUpdate,
    permission: 'access sku location',
  },
  {
    path: '/sku_location/update/:id',
    name: 'SkuLocationCreateOrUpdate',
    component: SkuLocationCreateOrUpdate,
    permission: 'access sku location',
  },

  //Sub Order Types
  {
    path: '/sub_order_types',
    name: 'OrderSubType',
    component: OrderSubTypes,
    exact: true,
    permission: 'access sub order types',
  },
  {
    path: '/sub_order_type/create',
    name: 'OrderSubTypesCreateOrUpdate',
    component: OrderSubTypesCreateOrUpdate,
    permission: 'access sub order types',
  },
  {
    path: '/sub_order_type/update/:id',
    name: 'OrderSubTypesCreateOrUpdate',
    component: OrderSubTypesCreateOrUpdate,
    permission: 'access sub order types',
  },

  // Profile
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    permission: 'access profile',
  },
  // Xero
  {
    path: '/Xero',
    name: 'Xero',
    component: Xero,
    permission: 'access xero',
  },
  // daily-reports
  {
    path: '/daily-reports',
    name: 'daily-reports',
    component: DailyReports,
    permission: 'access xero',
  },
  // monthly-delivery-report
  {
    path: '/custom-reports',
    name: 'custom-reports',
    component: CustomReports,
    permission: 'access xero',
  },
  // spread-report
  {
    path: '/spread-reports',
    name: 'spread-reports',
    component: SpreadReport,
    permission: 'access xero',
  },
]
export default routes
