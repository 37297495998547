import React from 'react'
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer'
import interreg from './../../../assets/PDFFonts/Inter-Regular.ttf'
import interbld from './../../../assets/PDFFonts/Inter-Bold.ttf'

Font.register({
  family: 'Inter',
  src: interreg,
})

Font.register({
  family: 'InterB',
  src: interbld,
})

// Create styles
const styles = StyleSheet.create({
  inline: {
    display: 'inline-block',
    fontSize: 12,
    lineHeight: 1.5,
  },
  heading: {
    fontSize: 17,
    lineHeight: 1.5,
    fontWeight: 700,
    fontFamily: 'InterB',
  },

  bold: {
    fontSize: 10,
    lineHeight: 1.5,
    fontWeight: 700,
    fontFamily: 'InterB',
  },

  bold9: {
    fontSize: 10,
    lineHeight: 1.5,
    fontWeight: 700,
    fontFamily: 'InterB',
  },

  bold9right: {
    fontSize: 6,
    lineHeight: 1.5,
    fontWeight: 700,
    marginLeft: 'auto',
    fontFamily: 'InterB',
  },

  bold10right: {
    fontSize: 6,
    lineHeight: 1.5,
    fontWeight: 700,
    fontFamily: 'InterB',
  },

  reg8: {
    color: 'blue',
    fontSize: 6,
    lineHeight: 1.5,
    fontWeight: 400,
    fontFamily: 'Inter',
  },

  reg9: {
    fontSize: 6,
    lineHeight: 1.5,
    fontWeight: 400,
    fontFamily: 'Inter',
  },

  reg10: {
    color: 'red',
    fontSize: 6,
    lineHeight: 1.5,
    fontWeight: 400,
    fontFamily: 'Inter',
  },

  page: {
    paddingTop: 10,
    paddingBottom: 30,
    paddingHorizontal: 10,
  },

  bottom: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    lineHeight: 1.2,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',

    lineHeight: 1.2,

    alignItems: 'self-start',
  },

  flexCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    lineHeight: 1.2,
  },

  margin_left: {
    marginLeft: 'auto',
  },

  tables: {
    backgroundColor: '#EFEFEF',
    margin: '0px',
    border: '0.5px solid black',
  },

  td: {
    width: '11.11%',
    padding: '5px 5px',
    border: '0.5px solid black',
  },

  th: {
    width: '11.11%',
    padding: '5px 5px',
    border: '0.5px solid black',
    backgroundColor: '#E8E8E8',
  },

  id: {
    width: '100%',
    padding: '5px 10px',
    border: '0.5px solid black',
    backgroundColor: '#E8E8E8',
  },

  layer2: {
    paddingLeft: 0,
  },
  layer3: {
    paddingLeft: 10,
  },
})

// Create Document Component
const DailyReport = (data) => {
  const dt = data?.item ?? null
  const map_dt = data?.map_data ?? null

  if (!dt || !map_dt) {
    return (
      <Document>
        <Page
          onRender={(e) => console.log(e)}
          size="A4"
          orientation="landscape"
          style={styles.page}
        >
          Error
        </Page>
      </Document>
    )
  }

  const formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  const returnDate = () => {
    var currentdate = new Date()
    var datetime =
      currentdate.getDate() +
      '/' +
      (currentdate.getMonth() + 1) +
      '/' +
      currentdate.getFullYear() +
      ' - ' +
      currentdate.getHours() +
      ':' +
      currentdate.getMinutes() +
      ':' +
      currentdate.getSeconds()

    return datetime
  }

  const zones = (data, map_dt) => {
    return (
      <>
        <Text style={styles.bold9right}>Date: {returnDate()}</Text>

        {Object.keys(data).map((firstLayer, i) => {
          return (
            <View
              break={i > 0}
              key={i}
              style={{ flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}
            >
              <div
                style={{
                  width: '100px',
                  border: '1px solid black',
                  padding: 3,
                  backgroundColor: '#E8E8E8',
                }}
              >
                <Text style={styles.reg9}>
                  ZONE:{' '}
                  <Text style={styles.reg8}>
                    {map_dt.dzone.find((x) => x.id === Number(firstLayer))?.name}{' '}
                  </Text>
                </Text>
              </div>

              {Object.keys(data[firstLayer]).map((secondLayer, j) => (
                <>
                  <div
                    style={{
                      width: '100px',
                      border: '1px solid black',
                      padding: 3,
                      backgroundColor: '#E8E8E8',
                    }}
                  >
                    <Text style={styles.reg9}>
                      TYPE:{' '}
                      <Text style={styles.reg8}>
                        {map_dt.otype.find((x) => x.id === Number(secondLayer))?.name}{' '}
                      </Text>
                    </Text>
                  </div>

                  {Object.keys(data[firstLayer][secondLayer]).map((thirdLayer, k) => (
                    <>
                      <div
                        style={{
                          width: '100px',
                          border: '1px solid black',
                          padding: 3,
                        }}
                      >
                        <Text style={styles.reg9}>
                          MACHINE:{' '}
                          <Text style={styles.reg8}>
                            {map_dt.machine.find((x) => x.id === Number(thirdLayer))?.name}
                          </Text>
                        </Text>
                      </div>

                      <div>
                        {data[firstLayer][secondLayer][thirdLayer].map((data, l) => (
                          <View wrap={false} key={l}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              <div
                                data-ther="id"
                                style={{
                                  width: '6%',
                                  border: '0.5px solid black',
                                  borderBottom: '2px solid black',
                                }}
                              >
                                <div
                                  key={l}
                                  style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 3,
                                    flexGrow: 1,
                                  }}
                                >
                                  <Text style={styles.reg9}>{data.id}</Text>
                                </div>
                              </div>
                              <div
                                data-ther="other"
                                style={{
                                  width: '100%',
                                }}
                              >
                                <div data-ther="row1">
                                  <div style={styles.flex}></div>
                                  <div data-ther="th" style={styles.flex}>
                                    <div
                                      style={{
                                        width: '11%',
                                        padding: '5px 5px',
                                        border: '0.5px solid black',
                                      }}
                                    >
                                      <Text style={styles.reg9}>{formatDate(data.created_at)}</Text>
                                    </div>

                                    <div
                                      style={{
                                        width: '20%',
                                        padding: '5px 5px',
                                        border: '0.5px solid black',
                                      }}
                                    >
                                      <Text style={styles.reg9}>{data.customer.account_name}</Text>
                                      <Text style={styles.reg9}>
                                        {data.customer.account_contact_name}
                                      </Text>
                                      <Text style={styles.reg9}>
                                        M: {data.customer.phone_mobile}
                                      </Text>
                                      <Text style={styles.reg9}>H: {data.customer.phone_home}</Text>
                                      <Text style={styles.reg9}>W: {data.customer.phone_work}</Text>
                                    </div>

                                    <div
                                      style={{
                                        width: '20%',
                                        padding: '5px 5px',
                                        border: '0.5px solid black',
                                      }}
                                    >
                                      <Text style={styles.reg9}>
                                        {data?.customer_address?.address}
                                      </Text>
                                      <Text style={styles.reg9}>
                                        {data?.customer_address?.resident_name}
                                      </Text>
                                      <Text style={styles.reg9}>
                                        M: {data?.customer_address?.resident_contact}
                                      </Text>
                                    </div>

                                    <div style={styles.td}>
                                      {data?.order_list[0]?.order_list_products.length >= 2 && (
                                        <Text style={styles.bold10right}>
                                          MIX - {data.tons_ordered}
                                        </Text>
                                      )}

                                      {data?.order_list[0]?.order_list_products.length >= 2
                                        ? data?.order_list[0]?.order_list_products.length >= 1 &&
                                          data?.order_list[0]?.order_list_products.map(
                                            (data, i) => (
                                              <Text key={i} style={styles.reg10}>
                                                {data.product_name ?? ''}{' '}
                                                {Number(data.quantity).toFixed(3) ?? 100}
                                              </Text>
                                            ),
                                          )
                                        : data?.order_list[0]?.order_list_products.length >= 1 &&
                                          data?.order_list[0]?.order_list_products.map(
                                            (data, i) => (
                                              <Text key={i} style={styles.bold10right}>
                                                {data.product_name ?? ''}{' '}
                                                {Number(data.quantity).toFixed(3) ?? 100}
                                              </Text>
                                            ),
                                          )}

                                      {/* {data?.order_list[0]?.order_list_products.length <= 1 &&
                                          data?.order_list[0]?.order_list_products.map(
                                            (data, i) => (
                                              <Text key={i} style={styles.reg9}>
                                                Total :{' '}
                                                {Number(
                                                  data?.quantity *
                                                    data?.quantitytype?.quantity_value,
                                                ).toFixed(3)}
                                              </Text>
                                            ),
                                          )}

                                        {data?.order_list[0]?.order_list_products.length <= 1 && (
                                          <Text key={i} style={styles.reg9}>
                                            Delivered : {Number(data.tons_actual).toFixed(3)}
                                          </Text>
                                        )}
                                        {data?.order_list[0]?.order_list_products.length <= 1 && (
                                          <Text key={i} style={styles.reg9}>
                                            Remaining :{' '}
                                            {Number(data.tons_ordered - data.tons_actual).toFixed(
                                              3,
                                            )}
                                          </Text>
                                        )} */}
                                    </div>

                                    <div
                                      style={{
                                        width: '10%',
                                        padding: '5px 5px',
                                        border: '0.5px solid black',
                                      }}
                                    >
                                      <Text style={styles.reg9}>{data?.custom_spread_rate}</Text>
                                    </div>
                                    <div style={styles.td}>
                                      {data.operator.map((op, i) => (
                                        <div key={i}>
                                          <Text style={styles.reg9}>
                                            {op?.operator?.fname}{' '}
                                            {op?.operator?.fname && op?.operator?.lname}
                                          </Text>
                                        </div>
                                      ))}
                                    </div>
                                    <div
                                      style={{
                                        width: '9%',
                                        padding: '5px 5px',
                                        border: '0.5px solid black',
                                      }}
                                    >
                                      <Text style={styles.reg9}>
                                        {data.dockets_ready == 1
                                          ? 'Ready'
                                          : data.dockets_ready == 2
                                          ? 'Will Advice'
                                          : data.dockets_ready === 3
                                          ? 'In Progress'
                                          : ''}
                                      </Text>
                                    </div>
                                    <div
                                      style={{
                                        width: '9%',
                                        padding: '5px 5px',
                                        border: '0.5px solid black',
                                      }}
                                    >
                                      <Text style={styles.reg9}>
                                        {data.scheduled_date && formatDate(data.scheduled_date)}
                                      </Text>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <div
                                    data-ther="row2"
                                    style={{
                                      width: '25%',
                                      border: '0.5px solid black',
                                      borderBottom: '2px solid black',
                                    }}
                                  >
                                    <div
                                      style={{
                                        padding: 3,
                                      }}
                                    >
                                      <Text style={styles.reg9}>{data.customer_reference}</Text>
                                    </div>
                                  </div>
                                  <div
                                    data-ther="row2"
                                    style={{
                                      width: '25%',
                                      border: '0.5px solid black',
                                      borderBottom: '2px solid black',
                                    }}
                                  >
                                    <div
                                      style={{
                                        padding: 3,
                                      }}
                                    >
                                      <Text style={styles.reg9}>{data.driver_note}</Text>
                                    </div>
                                  </div>
                                  <div
                                    data-ther="row2"
                                    style={{
                                      width: '25%',
                                      border: '0.5px solid black',
                                      borderBottom: '2px solid black',
                                    }}
                                  >
                                    {/* <div
                                              style={{
                                                padding: 3,
                                                borderBottom: '1px solid black',
                                                borderRight: '1px solid black',
                                                width: '75%',
                                                backgroundColor: '#E8E8E8',
                                              }}
                                            >
                                              <Text style={styles.reg9}>Location Note</Text>
                                            </div> */}
                                    <div
                                      style={{
                                        padding: 3,
                                      }}
                                    >
                                      <Text style={styles.reg9}>
                                        {data?.customer_address?.location_note}
                                      </Text>
                                    </div>
                                  </div>
                                  <div
                                    data-ther="row2"
                                    style={{
                                      width: '25%',
                                      border: '0.5px solid black',
                                      borderBottom: '2px solid black',
                                    }}
                                  >
                                    <div
                                      style={{
                                        padding: 3,
                                      }}
                                    >
                                      <Text style={styles.reg9}>{data.order_note}</Text>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </View>
                        ))}
                      </div>
                    </>
                  ))}
                </>
              ))}
            </View>
          )
        })}
      </>
    )
  }
  return (
    <Document>
      <Page
        onRender={(e) => console.log(e)}
        size="A4"
        orientation="landscape"
        wrap={false}
        style={styles.page}
      >
        {zones(dt, map_dt)}
      </Page>
    </Document>
  )
}

export default DailyReport
