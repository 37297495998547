import { CustomerTerms } from './routes'
import axios from 'axios'

export const getCustomerTerms = () => {
  return axios.get(`${CustomerTerms}`)
}

export const deleteCustomerTerms = (id) => {
  return axios.delete(`${CustomerTerms}/${id}`)
}

export const getCustomerTermSingle = (id) => {
  return axios.get(`${CustomerTerms}/${id}/edit`)
}

export const createCustomerTerms = (data) => {
  return axios.post(`${CustomerTerms}`, data)
}

export const updateCustomerTerms = (id, data) => {
  return axios.put(`${CustomerTerms}/${id}`, data)
}
