import React, { useState, useEffect } from 'react'
import { useHistory, NavLink } from 'react-router-dom'
//import { Usercntxt } from '../../context/UserContext'
import Preloader from 'src/views/preloader/Preloader'
import format from 'date-fns/format'
import swal from 'sweetalert2'
import axios from 'axios'
import $ from 'jquery'

const Xero = () => {
  const [loading, setLoading] = useState(true)
  const [invoiceXero, setInvoiceXero] = useState([])
  const [productsXero, setProductsXero] = useState([])
  const [approvedInvoice, setApprovedInvoice] = useState([])
  const [isSending, setIsSending] = useState(false)
  //const user = useContext(Usercntxt)
  const history = useHistory()

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const res = await axios.get(`/api/xero_index`)
        if (res.data.status === 200) {
          setProductsXero(res.data.xeroItems.body.Items)
          setApprovedInvoice(res.data.approvedInvoice.body.Invoices)
          console.log('Approved Invoices', res.data.XeroItems)
          setInvoiceXero(res.data.orders)
        } else if (res.data.status === 500) {
          showAlert('warning', 'Warning', res.data.message)
          history.push('/')
        }
      } catch (error) {
        showAlert('error', 'Error', 'Failed to fetch data.')
      } finally {
        setTimeout(() => {
          $('#dataTable').DataTable({
            scrollX: false,
            pageLength: 100,
          })
        }, 10)
        setLoading(false)
      }
    }

    fetchOrders()
  }, [history])

  const showAlert = (icon, title, text) => {
    swal.fire({ icon, title, text })
  }

  const prepareXeroData = (valueXeroState) => {
    // Create line items from order_list

    const DocketLineItems = valueXeroState.order_list?.flatMap((products) => {
      // Check if we need to map based on dockets or products
      return products.docket_numbers.flatMap((docket) => {
        let totalProportionatePrice = 0

        products.order_list_products.forEach((product) => {
          const mixPercentage = parseFloat(product.mix_percentage).toFixed(3) // Given as 10%
          const unitPrice = parseFloat(product.unit_price).toFixed(3)
          const proportionatePrice = (mixPercentage / 100) * unitPrice

          totalProportionatePrice += proportionatePrice
        })

        const machine_xero = valueXeroState.machine.machine_code
        const sku_xero = valueXeroState.skulocation.xero_code

        const skuMix = machine_xero + 'MIX.' + sku_xero

        console.log('machines', skuMix)

        return (
          products.order_list_products.slice(0, 1).map((product) => {
            // Take only the first product for each docket
            const xeroItem = productsXero.find((xItem) => xItem.Code == skuMix)
            console.log('xeroItem', xeroItem.SalesDetails.AccountCode)
            return {
              ItemCode: skuMix,
              Description: xeroItem
                ? xeroItem.Description + ' - Docket Number ' + docket.docket_number
                : 'N/A', // This will use the description from productsXero or a default text if not found.
              Quantity: docket.weight === 'N/A' || docket.weight === null ? 0 : docket.weight,
              UnitAmount: parseFloat(totalProportionatePrice).toFixed(2),
              TaxType: 'OUTPUT',
              AccountCode: xeroItem.SalesDetails.AccountCode,
              Tracking: [
                {
                  Name: 'Location',
                  Option: docket.skulocation?.name,
                },
                {
                  Name: 'Division',
                  Option: ['BULK SELF PICKUP', 'BULK DUMPED', 'BULK AUGERED'].includes(
                    valueXeroState.ordertype.name,
                  )
                    ? 'Mining'
                    : valueXeroState.ordertype.name.trim() === 'SPREAD'
                    ? 'Spreading'
                    : 'Undefined',
                },
              ],
            }
          }) || []
        )
      })
    })

    const LineItems = valueXeroState.order_list?.flatMap((products) => {
      // Check if we need to map based on dockets or products
      return products.docket_numbers.flatMap((docket) => {
        let totalProportionatePrice = 0

        products.order_list_products.forEach((product) => {
          const mixPercentage = parseFloat(product.mix_percentage).toFixed(3) // Given as 10%
          const unitPrice = parseFloat(product.unit_price).toFixed(3)
          const proportionatePrice = (mixPercentage / 100) * unitPrice

          totalProportionatePrice += proportionatePrice
        })

        const machine_xero = valueXeroState.machine.machine_code
        const sku_xero = valueXeroState.skulocation.xero_code

        const skuMix = machine_xero + 'MIX.' + sku_xero

        console.log('machines', skuMix)

        return (
          products.order_list_products.slice(0, 1).map((product) => {
            // Take only the first product for each docket
            const xeroItem = productsXero.find((xItem) => xItem.Code == product.xero_code)
            console.log('xeroItem', xeroItem)
            return {
              ItemCode: product.xero_code,
              Description: xeroItem
                ? xeroItem.Description + ' - Docket Number ' + docket.docket_number
                : 'N/A', // This will use the description from productsXero or a default text if not found.
              Quantity: docket.weight === 'N/A' || docket.weight === null ? 0 : docket.weight,
              UnitAmount: parseFloat(totalProportionatePrice).toFixed(2),
              TaxType: 'OUTPUT',
              AccountCode: xeroItem.SalesDetails.AccountCode,
              Tracking: [
                {
                  Name: 'Location',
                  Option: docket.skulocation?.name,
                },
                {
                  Name: 'Division',
                  Option: ['BULK SELF PICKUP', 'BULK DUMPED', 'BULK AUGERED'].includes(
                    valueXeroState.ordertype.name,
                  )
                    ? 'Mining'
                    : valueXeroState.ordertype.name.trim() === 'SPREAD'
                    ? 'Spreading'
                    : 'Undefined',
                },
              ],
            }
          }) || []
        )
      })
    })

    /* const LineItems = valueXeroState.order_list?.flatMap(
      (products) =>
        products.order_list_products?.map((product) => {
          const xeroItem = productsXero.find((xItem) => xItem.Code === product.xero_code)
          console.log('xeroItem', xeroItem.SalesDetails.AccountCode)
          return {
            ItemCode: product.xero_code,
            Description: xeroItem ? xeroItem.Description : 'N/A', // This will use the description from productsXero or a default text if not found.
            Quantity:
              product.quantity === 'N/A' || product.quantity === null ? 0 : product.quantity,
            UnitAmount:
              product.unit_price === 'N/A' || product.unit_price === null
                ? 0.0
                : product.unit_price,
            TaxType: 'OUTPUT',
            AccountCode: xeroItem.SalesDetails.AccountCode,
            Tracking: [
              {
                Name: 'Location',
                Option: valueXeroState.skulocation.name,
              },
              {
                Name: 'Division',
                Option:
                  valueXeroState.ordertype.name === 'BULK SELF PICKUP' ||
                  valueXeroState.ordertype.name === 'BULK DUMPED' ||
                  valueXeroState.ordertype.name === 'BULK AUGERED'
                    ? 'Mining'
                    : valueXeroState.ordertype.name.trim() === 'SPREAD'
                    ? 'Spreading'
                    : 'Undefined',
              },
            ],
          }
        }) || [],
    ) */

    console.log('LineItems', LineItems)
    console.log('DocketLineItems', DocketLineItems)

    // Create other line items
    const OtherLineItems =
      valueXeroState.line_items?.flatMap((lineItem) => ({
        ItemCode: '',
        Description: lineItem.description,
        Quantity: lineItem.quantity === 'N/A' || lineItem.quantity === null ? 0 : lineItem.quantity,
        UnitAmount: lineItem.amount === 'N/A' || lineItem.amount === null ? 0.0 : lineItem.amount,
        AccountCode: '',
      })) || []

    // Add order note as a line item
    const OtherLineItemsWithOrderNote = [
      ...OtherLineItems,
      ...(valueXeroState.order_note
        ? [
            {
              ItemCode: '',
              Description: valueXeroState.order_note,
              Quantity: '',
              UnitAmount: '',
              AccountCode: '',
            },
          ]
        : []),
    ]

    const docketNumbers = valueXeroState.order_list
      .map((order) => {
        return order.docket_numbers.map((docket) => docket.docket_number).join(', ')
      })
      .join(', ')

    console.log('Docket numbers:', docketNumbers)

    const DocketNumbers = [
      {
        ItemCode: '',
        Description: docketNumbers,
        Quantity: '',
        UnitAmount: '',
        AccountCode: '',
      },
    ]

    // Combine the two sets of line items
    let CombinedLineItems = []

    valueXeroState.order_list.map((product_docket) => {
      console.log('value', product_docket.order_list_products.length)

      if (product_docket.order_list_products.length > 1) {
        console.log('Mix')
        CombinedLineItems = [...DocketLineItems, ...OtherLineItemsWithOrderNote]
      } else {
        console.log('Product')
        CombinedLineItems = [...LineItems, ...OtherLineItemsWithOrderNote]
      }
    })

    console.log('xero', CombinedLineItems)

    const Attachments = valueXeroState.order_list?.flatMap(
      (images) =>
        images.docket_numbers?.map((image) => ({
          FileName: image.imageName,
          Url: image.imageURL,
          MimeType: 'image/png',
          ContentLength: '10294',
        })) || [],
    )

    //Date
    const dateString =
      valueXeroState.dockets_complete_time === null
        ? valueXeroState.updated_at
        : valueXeroState.dockets_complete_time
    const customerTerms = valueXeroState.customer.customer_terms.name
    const year = parseInt(dateString.split('-')[0], 10) // Convert to integer
    const month = parseInt(dateString.split('-')[1], 10) - 1 // Convert to integer and adjust for JavaScript month index
    console.log(year, month + 1) // Output the correct month number for clarity

    function getLastDayOfMonth(year, month) {
      // Create a date object for the first day of the next month
      const lastDay = new Date(year, month + 1, 0) // Setting day as 0 gives the last day of the previous month
      return lastDay
    }

    const lastDayOfMonth = getLastDayOfMonth(year, month)
    console.log('End of the month:', lastDayOfMonth.toDateString())

    // Create a variable to store the due date and initialize it
    let dueDate = new Date(lastDayOfMonth) // Initialize dueDate with the last day of the month

    // Check customer terms and adjust due date accordingly
    if (customerTerms === 'NET30') {
      dueDate.setDate(lastDayOfMonth.getDate() + 30) // Add 30 days to the last day of the month
    } else if (customerTerms === 'NET14') {
      dueDate.setDate(lastDayOfMonth.getDate() + 14) // Add 14 days
    } else if (customerTerms === 'NET7') {
      dueDate.setDate(lastDayOfMonth.getDate() + 7) // Add 7 days
    } else {
      dueDate = 'Invalid Date'
    }

    // Function to format date in year-month-date format
    function formatDateToYearMonthDay(date) {
      if (date === 'Invalid Date') {
        return 'Invalid Date'
      }
      return (
        date.getFullYear() +
        '-' +
        ('0' + (date.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + date.getDate()).slice(-2)
      )
    }

    // Check if valueXeroState.dockets_complete_time is "NaN-aN-aN"
    const formattedDueDate = isNaN(Date.parse(dateString))
      ? 'Invalid Date'
      : formatDateToYearMonthDay(dueDate)

    console.log('Customer terms:', customerTerms)
    console.log('Due date:', formattedDueDate) // Format the due date or show "Invalid Date"
    console.log('Date:', dateString)

    const baseData = {
      Reference: valueXeroState.customer_reference,
      Type: 'ACCREC',
      Contact: {
        ContactID: valueXeroState.customer.contact_id,
        Name: valueXeroState.customer.account_name,
      },
      DateString:
        formattedDueDate === 'Invalid Date'
          ? valueXeroState.updated_at
          : valueXeroState.dockets_complete_time,
      DueDateString:
        formattedDueDate === 'Invalid Date' ? valueXeroState.updated_at : formattedDueDate,
      LineItems: CombinedLineItems,
      Attachments: Attachments ? '' : Attachments,
    }

    return valueXeroState.invoice_id
      ? { ...baseData, InvoiceID: valueXeroState.invoice_id, InvoiceNumber: valueXeroState.id }
      : { ...baseData, InvoiceNumber: valueXeroState.id }
  }

  const sendToXero = async (id) => {
    setIsSending(true)
    const valueXeroState = invoiceXero.find((x) => x.id === Number(id))
    const xeroData = prepareXeroData(valueXeroState)
    console.log('final', xeroData)

    try {
      const endpoint = valueXeroState.invoice_id
        ? `/api/update_xero_invoice`
        : `/api/create_xero_invoice`
      const res = await axios.post(endpoint, xeroData)
      if (res.data.status === 200) {
        const res = await axios.get(`/api/xero_index`)
        if (res.data.status === 200) {
          setInvoiceXero(res.data.orders)
        }
        showAlert('success', 'Success', 'Xero Invoice added successfully')
        history.push('/xero')
      }
    } catch (error) {
      console.log(xeroData)
      if (
        error.response &&
        error.response.status === 500 &&
        (!xeroData.Attachments || xeroData.Attachments.length === 0)
      ) {
        // Treat the error as a success if the attachments are empty
        const res = await axios.get(`/api/xero_index`)
        if (res.data.status === 200) {
          setInvoiceXero(res.data.orders)
        }
        showAlert(
          'success',
          'Success',
          'Xero Invoice added successfully despite missing attachments',
        )
        history.push('/xero')
      } else {
        showAlert('error', 'Error', 'Something went wrong.')
      }
    }

    setIsSending(false)
  }

  if (loading) {
    return <Preloader />
  }

  return (
    <>
      {/* <h1>Xero {tenantName}</h1>- <a href="https://nql-api.dblq.io/xero/connect">Login</a> */}
      <div className="content-wrapper">
        <nav aria-label="breadcrumb p-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/">Dashboard</NavLink>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Orders
            </li>
          </ol>
        </nav>
        <h3 className="text-dark">Xero</h3>

        {/* body content dashboard */}
        <div className="content">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Xero Order</h5>
            </div>
            <div className="card-body">
              <table id="dataTable" className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th className="small">#</th>
                    <th className="small">Invoice Number</th>
                    <th className="small">Code</th>
                    <th className="small">Customer Reference</th>
                    <th className="small">Address Contact</th>
                    <th className="small">Product(s)</th>
                    <th className="small">Delivery Location</th>
                    <th className="small">Last Modified</th>
                    <th className="small">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceXero.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <p className="small">#{item.id}</p>
                      </td>
                      <td>
                        <p className="small">
                          {approvedInvoice.some((approved) => approved.InvoiceNumber == item.id)
                            ? approvedInvoice.find((approved) => approved.InvoiceNumber == item.id)
                                .InvoiceNumber
                            : '#'}
                        </p>
                      </td>
                      <td>
                        <p className="small">{item.customer.code}</p>
                      </td>
                      <td>
                        <p className="small">
                          {item.customer_reference === null ? 'N/A' : item.customer_reference}
                        </p>
                      </td>
                      <td>
                        <p className="small">
                          <span className="fw-bold">{item.customer.account_name}</span>
                          <br />
                          <span className="fw-bold">H:</span>
                          {item.customer.phone_home}
                          <br />
                          <span className="fw-bold">W:</span>
                          {item.customer.phone_work}
                          <br />
                          <span className="fw-bold">M:</span>
                          {item.customer.phone_mobile}
                        </p>
                      </td>
                      <td>
                        {item.order_list?.map((product_code, i) => (
                          <div key={i}>
                            {product_code.order_list_products?.map((code, ind) => (
                              <span className="badge bg-dark mx-1" key={ind}>
                                {code.product_name}
                              </span>
                            ))}
                          </div>
                        ))}
                      </td>
                      <td>
                        <p className="small">{item.orderdeliveryzone.name}</p>
                      </td>
                      <td>{format(new Date(item.updated_at), 'dd/MM/yyyy | HH:mm:ss')}</td>
                      <td>
                        {/* Check if the invoice has been approved or not */}
                        {approvedInvoice.some((approved) => approved.InvoiceNumber == item.id) ? (
                          <button
                            type="button"
                            className="btn btn-block btn-outline-success btn-sm"
                          >
                            Invoice Approved
                          </button>
                        ) : item.is_xero === null ? (
                          <span>Its Null</span>
                        ) : item.is_xero === 0 ? (
                          <button type="button" className="btn btn-block btn-outline-info btn-sm">
                            Xero Updated
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-block btn-outline-success btn-sm"
                            onClick={() => sendToXero(item.id)}
                            disabled={isSending}
                          >
                            {isSending ? 'Sending...' : 'Send to Xero'}
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* <pre>{JSON.stringify(tableList, null, 2)}</pre> */}
        </div>
      </div>
    </>
  )
}

export default Xero
