import React, { useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import axios from 'axios'
import Preloader from '../preloader/Preloader'
import swal from 'sweetalert2'
import $ from 'jquery'
import DataTable from 'datatables.net'
import pLimit from 'p-limit'

function Customers() {
  const [loading, setLoading] = useState(true)
  const [customerList, setCustomerList] = useState([])
  const [xeroCustomerList, setXeroCustomerList] = useState([])

  useEffect(() => {
    axios.get(`/api/show_customers`).then((res) => {
      if (res.data.status === 200) {
        setCustomerList(res.data.customer)
        setXeroCustomerList(res.data.xeroContact.body.Contacts)
      }
      setTimeout(() => {
        $('#dataTable').DataTable({
          scrollX: false,
          pageLength: 100,
        })
      }, 10)
      setLoading(false)
    })
  }, [])

  // Compare data once after it's loaded
  useEffect(() => {
    if (customerList.length && xeroCustomerList.length) {
      const limit = pLimit(10) // limit to 10 concurrent requests
      const updatePromises = customerList.map((customer) => {
        return limit(() => {
          const matchedXeroCustomer = xeroCustomerList.find(
            (xeroCustomer) => customer.account_name === xeroCustomer.Name,
          )

          if (matchedXeroCustomer.ContactID) {
            console.log(`Contact ID already exists for customer ${customer.id}`)
            return Promise.resolve()
          }

          if (matchedXeroCustomer) {
            console.log(`Match found: ${matchedXeroCustomer.ContactID}`)
            return submitForm(customer.id, matchedXeroCustomer.ContactID)
          } else {
            console.log('Not matching')
            return Promise.resolve()
          }
        })
      })

      Promise.all(updatePromises)
        .then(() => console.log('All updates complete'))
        .catch((error) => console.error('Error in updating contacts:', error))
    }
  }, [customerList, xeroCustomerList])

  const submitForm = async (customerId, contactId) => {
    try {
      const response = await axios.put(`/api/update_contact_id/${customerId}`, {
        ContactID: contactId,
      })
      console.log('Update response:', response.data)
    } catch (error) {
      console.error('Error updating contact ID:', error)
    }
  }

  const deleteCustomer = (e, id) => {
    e.preventDefault()

    const thisClicked = e.currentTarget

    swal
      .fire({
        title: 'Are you sure you want to proceed?',
        text: 'This will completely delete the customer permanently',
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'Cancel!',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Proceed!',
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios.delete(`/api/delete_customer/${id}`).then((res) => {
            if (res.data.status === 200) {
              swal.fire('Deleted!', res.data.message, 'success')
              thisClicked.closest('tr').remove()
            } else if (res.data.status === 404) {
              swal.fire('Error', res.data.message, 'error')
            }
          })
        } else if (result.isDenied) {
          swal.fire('Changes are not saved', '', 'info')
        }
      })
  }

  var viewProduct_HTMLTable = ''
  var viewProduct_HTMLTable_Xero = ''
  if (loading) {
    return <Preloader />
  } else {
    viewProduct_HTMLTable_Xero = xeroCustomerList.map((item, i) => {
      const matchingCustomer = customerList.find(
        (customer) => customer.contact_id === item.ContactID,
      )

      return (
        <tr key={item.id}>
          <td>{i + 1}</td>
          <td>
            {item.Name} {item.ContactID}
          </td>
          <td>{item.FirstName}</td>
          <td>{item.CompanyNumber}</td>
          <td>
            {item.Phones.map((phone, i) => (
              <span key={i}>{phone.PhoneNumber}</span>
            ))}
          </td>
          <td>{item.EmailAddress}</td>
          <td>
            {matchingCustomer?.customer_address.map((address, i) => (
              <div key={i}>
                <span className="fw-bold">Address: </span> {address?.address}
                <br />
              </div>
            ))}
          </td>
          <td>
            <Link
              to={`/customers/update/${item.ContactID}`}
              className="btn btn-block btn-outline-info btn-sm me-2 mb-2"
            >
              <i className="far fa-edit"></i> Edit
            </Link>
          </td>
        </tr>
      )
    })
  }
  return (
    <div className="content-wrapper">
      <nav aria-label="breadcrumb p-3">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <NavLink to="/">Dashboard</NavLink>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Customers
          </li>
        </ol>
      </nav>
      <h3 className="text-dark">All Customers</h3>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2"></div>
        </div>
      </div>

      <div className="content">
        <div className="card">
          <div className="card-header">
            <h5 className="card-title">All Customers</h5>
          </div>
          <div className="card-body">
            <table id="dataTable" className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Account Name</th>
                  <th>Account Contact Name</th>
                  <th>Code</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Address</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>{viewProduct_HTMLTable_Xero}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Customers
