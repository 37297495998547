import { OrderTypes } from './routes'
import axios from 'axios'

export const getOrderTypes = () => {
  return axios.get(`${OrderTypes}`)
}

export const getOrderTypeSingle = (id) => {
  return axios.get(`${OrderTypes}/${id}/edit`)
}

export const createOrderTypes = (data) => {
  return axios.post(`${OrderTypes}`, data)
}

export const updateOrderTypes = (id, data) => {
  return axios.put(`${OrderTypes}/${id}`, data)
}

export const deleteOrderTypes = (id) => {
  return axios.delete(`${OrderTypes}/${id}`)
}
