import { SkuLocation } from './routes'
import axios from 'axios'

export const getSkuLocation = () => {
  return axios.get(`${SkuLocation}`)
}

export const getSkuLocationSingle = (id) => {
  return axios.get(`${SkuLocation}/${id}/edit`)
}

export const createSkuLocation = (data) => {
  return axios.post(`${SkuLocation}`, data)
}

export const updateSkuLocation = (id, data) => {
  return axios.put(`${SkuLocation}/${id}`, data)
}

export const deleteSkuLocation = (id) => {
  return axios.delete(`${SkuLocation}/${id}`)
}
