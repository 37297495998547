import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import swal from 'sweetalert2'
import Preloader from '../preloader/Preloader'
import $ from 'jquery'

//components
import { DataTableItem, UserRoute, BreadCrumb, Card } from 'src/components'

//services
import { getOperators, deleteOperators } from 'src/services'

function Operators() {
  const [loading, setLoading] = useState(true)
  const [inputList, setInputList] = useState([])

  useEffect(() => {
    getOperators()
      .then((res) => {
        if (res.data.status === 200) {
          setInputList(res.data.operator)
        }
        setTimeout(() => {
          $('#dataTable').DataTable({
            scrollX: false,
            pageLength: 100,
          })
        }, 10)
        setLoading(false)
      })
      .catch(() => {
        setTimeout(() => {
          $('#dataTable').DataTable({
            scrollX: false,
            pageLength: 100,
          })
        }, 10)
        setLoading(false)
      })
  }, [])

  const deleteRecord = (e, id) => {
    e.preventDefault()

    const thisClicked = e.currentTarget

    swal
      .fire({
        title: 'Are you sure you want to proceed?',
        text: 'This will completely delete the Operators permanently',
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'Cancel!',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Proceed!',
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteOperators(id)
            .then((res) => {
              if (res.data.status === 200) {
                swal.fire('Deleted!', res.data.message, 'success')
                thisClicked.closest('tr').remove()
              } else if (res.data.status === 404) {
                swal.fire('Error', res.data.message, 'error')
              }
            })
            .catch(() => {
              setTimeout(() => {
                $('#dataTable').DataTable({
                  scrollX: false,
                  pageLength: 100,
                })
              }, 10)
              setLoading(false)
            })
        } else if (result.isDenied) {
          swal.fire('Changes are not saved', '', 'info')
        }
      })
  }

  const renderRowActions = (item) => {
    return (
      <>
        <Link to={`/operators/update/${item.id}`} className="btn btn-info me-2">
          Edit
        </Link>
        <button onClick={(e) => deleteRecord(e, item.id)} className="btn btn-danger">
          Delete
        </button>
      </>
    )
  }

  if (loading) {
    return <Preloader />
  }

  const columns = [
    { label: 'Fist Name', key: 'fname' },
    { label: 'Last Name', key: 'lname' },
    { label: 'Phone Number', key: 'phone' },
    // ... other columns
  ]

  const title = 'Operators'

  const breadcrumb = [
    {
      title: title,
      name: 'Add Operators',
      subtitle: '',
      subtitleroute: '',
    },
  ]

  const buttonValues = [
    {
      title: 'Add Operators',
      variants: 'btn btn-primary my-3 mr-2',
      routes: '/operators/create',
    },
  ]

  const cardValues = [
    {
      title: title,
    },
  ]

  return (
    <div>
      <BreadCrumb data={breadcrumb} />
      <UserRoute data={buttonValues} />

      <Card data={cardValues}>
        {<DataTableItem data={inputList} columns={columns} renderRowActions={renderRowActions} />}
      </Card>
    </div>
  )
}

export default Operators
