import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import swal from 'sweetalert'
import Preloader from '../../preloader/Preloader'
import { CFormSelect } from '@coreui/react'

//services
import { getProducts, getProductMixSingle, createProductMix, updateProductMix } from 'src/services'

//components
import { BreadCrumb, Card } from 'src/components'
const CreateOrUpdate = () => {
  const history = useHistory()
  const { id } = useParams()
  const isUpdating = id !== undefined
  const [loading, setLoading] = useState(isUpdating)
  const [productList, setProductList] = useState([])
  const [inputList, setInputList] = useState({
    id: '',
    product_mix_list: [
      {
        id: '',
        product: {
          id: '',
        },
        contains_percentage: '',
        error_list: [],
      },
    ],
  })

  useEffect(() => {
    if (isUpdating) {
      getProductMixSingle(id)
        .then((res) => {
          if (res.data.status === 200) {
            setInputList(res.data.product_mix)
          } else {
            history.push('/products/productmix/')
          }
          setLoading(false)
        })
        .catch(() => {
          history.push('/products/productmix/')
          setLoading(false)
        })
    }
  }, [id, history, isUpdating])

  useEffect(() => {
    getProducts(id)
      .then((res) => {
        if (res.data.status === 200) {
          setProductList(res.data.products)
        } else {
          history.push('/products/productmix/')
        }
        setLoading(false)
      })
      .catch(() => {
        history.push('/products/productmix/')
        setLoading(false)
      })
  }, [id, history])

  const handleInput = (e) => {
    e.persist()
    setInputList({ ...inputList, [e.target.name]: e.target.value })
  }

  const handleChange = (e, index) => {
    const { name, value } = e.target
    const list = { ...inputList } //<-- object, not array

    if (name === 'id') {
      list.product_mix_list[index].product[name] = value
    }

    if (name === 'contains_percentage') {
      list.product_mix_list[index][name] = value
    }
    setInputList(list)
  }

  const handleAddInput = (e) => {
    e.preventDefault()
    setInputList({
      ...inputList,
      product_mix_list: [
        ...inputList.product_mix_list,
        {
          id: '',
          product: {
            id: '',
          },
          contains_percentage: '',
        },
      ],
    })
  }

  const handleRemoveInput = (e, i) => {
    e.preventDefault()
    const list = { ...inputList }
    list.product_mix_list.splice(i, 1)
    setInputList(list)
  }

  const submitForm = (e) => {
    e.preventDefault()
    const data = inputList

    const submitAction = isUpdating
      ? () => updateProductMix(id, data)
      : () => createProductMix(data)

    submitAction()
      .then((res) => {
        if (res.data.status === 200) {
          swal('Success', res.data.message, 'success')
          history.push('/products/productmix/')
        } else if (res.data.status === 400 || res.data.status === 422) {
          setInputList({ ...inputList, error_list: res.data.errors || {} })
        }
      })
      .catch((error) => {
        swal('Error', 'An error occurred', 'error')
      })
  }

  if (loading) {
    return <Preloader />
  }

  const title = 'Products Mix'
  const isanupdate = isUpdating ? 'Update' : 'Add'

  const breadcrumb = [
    {
      title: title,
      name: 'Add' + title,
      subtitle: isanupdate,
      subtitleroute: '/products/productmix/',
    },
  ]

  const cardValues = [
    {
      title: title,
      addorupdate: isanupdate,
    },
  ]

  return (
    <div>
      <BreadCrumb data={breadcrumb} />

      <div className="content">
        <div className="card card-primary">
          <Card data={cardValues}>
            <form onSubmit={submitForm}>
              <div className="row">
                {/* Docket Number */}
                <div className="col-lg-3 mb-3">
                  <div className="form-group">
                    <label className="pb-2">
                      Product Mix Code <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="product_docket"
                      onChange={handleInput}
                      value={inputList.product_docket}
                      placeholder="Product Mix Code"
                    />
                    <span className="text-danger">{inputList?.error_list?.product_docket}</span>
                  </div>
                </div>

                {inputList.product_mix_list.map((items, i) => {
                  return (
                    <>
                      <div key={i} className="row">
                        <div className="col-lg-4 mb-2">
                          <div className="form-group">
                            <label className="pb-2">
                              Products <span className="text-danger">*</span>{' '}
                            </label>
                            <div className="d-flex d-inline">
                              <CFormSelect
                                aria-label="Default select example"
                                name="id"
                                onChange={(e) => handleChange(e, i)}
                                value={items.product.id}
                              >
                                <option value={0}>-- select --</option>
                                {productList.map((item) => {
                                  return (
                                    <option value={item.id} key={item.id}>
                                      {item.product_name}
                                    </option>
                                  )
                                })}
                              </CFormSelect>
                            </div>
                            <span className="text-danger">
                              {inputList?.error_list &&
                                inputList.error_list['product_mix_list.' + i + '.product.id']}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-3 mb-2">
                          <div className="form-group">
                            <label className="pb-2">
                              Containing Ratio % <span className="text-danger">*</span>{' '}
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="contains_percentage"
                              onChange={(e) => handleChange(e, i)}
                              value={items.contains_percentage}
                              placeholder="%"
                            />
                            <span className="text-danger">
                              {inputList?.error_list &&
                                inputList.error_list[
                                  'product_mix_list.' + i + '.contains_percentage'
                                ]}
                            </span>
                          </div>
                        </div>

                        <div className="col-lg-2 mb-2 mt-4 pt-2">
                          {inputList.product_mix_list.length !== 1 && (
                            <button
                              className="btn btn-danger ms-2"
                              onClick={(e) => handleRemoveInput(e, i)}
                            >
                              Remove
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  )
                })}

                <div className="col-lg-2 mb-2">
                  <button className="btn btn-info mt-3" onClick={handleAddInput}>
                    Add Product Mix
                  </button>
                </div>
              </div>
              <button type="submit" className="btn btn-primary mt-3">
                {isUpdating ? 'Update' : 'Save'}
              </button>
            </form>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default CreateOrUpdate
