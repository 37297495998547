import { React, useEffect, useState } from 'react'
import { DateRangePicker } from 'rsuite'
import { BlobProvider } from '@react-pdf/renderer'
import Spread from './PDF/Spread'
import axios from 'axios'

function SpreadReport() {
  const [dateRange, setDateRange] = useState(null)
  const [error, setError] = useState('')
  const [loadingDate, setLoadingDate] = useState(false)
  const [pdfData, setPDFDate] = useState(null)
  const [map_data, setMapData] = useState({})

  const getDataReport = (data) => {
    setDateRange(data)
    setLoadingDate(true)
    if (data) {
      axios.post(`/api/get_custom_report`, { data }).then((res) => {
        if (res.data.spreadreport >= 0) {
          setError('No data found')
        } else {
          setError(null)
          setMapData({
            dzone: res.data.dzone,
            otype: res.data.otype,
            machine: res.data.machine,
            product: res.data.product,
            order_list_products: res.data.order_list_products,
          })
          //console.log(res.data.spreadreport)
          setPDFDate(res.data.spreadreport)
        }
      })
    } else {
      setError(null)
      setDateRange(null)
    }
    setLoadingDate(false)
  }

  return (
    <>
      <h1 className="mb-4">Spread Report</h1>

      <div className="d-flex align-items-end">
        <div className="d-flex flex-column ">
          <DateRangePicker
            appearance="default"
            placeholder="Select Date Range"
            style={{ width: 230 }}
            onChange={(e) => getDataReport(e)}
            value={dateRange}
          />
        </div>
        <div className="mx-3">
          {error ? (
            <div className="btn btn-block btn-outline-info btn-sm">{error}</div>
          ) : dateRange === null ? (
            <></>
          ) : loadingDate ? (
            <div className="btn btn-block btn-outline-info btn-sm">Loading....</div>
          ) : pdfData && !loadingDate ? (
            <>
              <BlobProvider document={<Spread item={pdfData} map_data={map_data} />}>
                {({ url, loading, error }) =>
                  !loading ? (
                    <a
                      href={url}
                      className="btn btn-block btn-outline-info btn-sm"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Download Report
                    </a>
                  ) : (
                    <a
                      className="btn btn-block btn-outline-info btn-sm"
                      href={url}
                      rel="noreferrer"
                      target="_blank"
                    >
                      Preparing Report
                    </a>
                  )
                }
              </BlobProvider>
            </>
          ) : (
            <></>
          )}

          {/* {error && <div className="btn btn-block btn-outline-info btn-sm">{error}</div>}

          {loadingDate && <div className="btn btn-block btn-outline-info btn-sm">Loading....</div>}
          {pdfData && !loadingDate && <>something</>} */}
        </div>
      </div>
    </>
  )
}

export default SpreadReport
