import React, { useState, useEffect } from 'react'
import { useHistory, NavLink, useParams } from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert'
import Preloader from 'src/views/preloader/Preloader'
import Select from 'react-select'
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput'

function Create() {
  const history = useHistory()

  let { id } = useParams()
  const [loading, setLoading] = useState()
  const [permission, setPermissions] = useState([])

  const [errorList, setError] = useState([])
  const [inputList, setInputList] = useState({
    id: '',
    name: '',
    guard_name: 'web',
    permissions: [],
  })

  const handleInput = (e) => {
    e.persist()
    setInputList({ ...inputList, [e.target.name]: e.target.value })
  }

  const handleChange = (e) => {
    let selected = inputList.permissions
    let find = selected.indexOf(Number(e.target.value))
    if (find > -1) {
      selected.splice(find, 1)
    } else {
      selected.push(Number(e.target.value))
    }
    setInputList({ ...inputList, permissions: selected })
  }

  useEffect(() => {
    if (id) {
      setLoading(true)
      axios.get(`api/edit_roles/${id}`).then((res) => {
        if (res.data.status === 200) {
          const permossion_array = []

          res.data.permissions.forEach(function (value, index, array) {
            permossion_array.push(value.id)
          })

          setInputList({
            ...inputList,
            id: res.data.role.id,
            name: res.data.role.name,
            permissions: permossion_array,
          })
        } else if (res.data.status === 404) {
          swal('Error', res.data.message, 'error')
          history.push('/roles')
        }
        setLoading(false)
      })
    }

    axios.get(`api/show_permissions`).then((res) => {
      if (res.data.status === 200) {
        setPermissions(res.data.permissions)
      } else if (res.data.status === 404) {
        swal('Error', res.data.message, 'error')
        history.push('/sub_order_types')
      }
      setLoading(false)
    })
  }, [history, id])

  const submitRoles = (e) => {
    e.preventDefault()

    if (id) {
      axios.put(`/api/update_roles/${id}`, inputList).then((res) => {
        if (res.data.status === 200) {
          swal('Success', res.data.message, 'success')
          setError([])
          history.push('/roles')
        } else if (res.data.status === 422) {
          setError(res.data.errors)
        }
      })
    } else {
      axios.post(`/api/create_roles`, inputList).then((res) => {
        if (res.data.status === 200) {
          swal('Success', res.data.message, 'success')
          history.push('/roles')
          setError([])
        } else if (res.data.status === 400) {
          setError(res.data.errors)
        }
      })
    }
  }

  if (loading) {
    return <Preloader />
  }
  return (
    <div className="content-wrapper">
      <nav aria-label="breadcrumb p-3">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <NavLink to="/roles">Roles</NavLink>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {id ? 'Update role' : 'Add role'}
          </li>
        </ol>
      </nav>
      <h3 className="text-dark">Role and Permissions</h3>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6 p-0"></div>
          </div>
        </div>
      </div>

      {/* body content dashboard */}
      <div className="content">
        <div className="card">
          <div className="card-header">
            <h5 className="card-title">{id ? 'Update role' : 'Add role'}</h5>
          </div>
          <div className="card-body">
            <form onSubmit={submitRoles} id="product_form">
              <div className="card-body">
                <div className="row">
                  {/* Name */}
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="pb-2">
                        Name <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        onChange={handleInput}
                        value={inputList.name}
                        placeholder="Name"
                      />
                      <span className="text-danger">{errorList.name}</span>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  {permission?.map((data, i) => (
                    <div key={i} className="col-md-3">
                      <FormCheckInput
                        id={data.id}
                        name={data.name}
                        onChange={(e) => handleChange(e)}
                        checked={inputList.permissions.includes(data.id) ? true : false}
                        value={data.id}
                      />
                      <label htmlFor={data.id} className="ms-3">
                        {data.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* <pre>{JSON.stringify(inputList, null, 2)}</pre> */}

              <div className="mx-3">
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Create
