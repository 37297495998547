import { Delivery_Zones } from './routes'
import axios from 'axios'

export const getDeliveryZone = () => {
  return axios.get(`${Delivery_Zones}`)
}

export const getDeliveryZoneSingle = (id) => {
  return axios.get(`${Delivery_Zones}/${id}/edit`)
}

export const createDeliveryZone = (data) => {
  return axios.post(`${Delivery_Zones}`, data)
}

export const updateDeliveryZone = (id, data) => {
  return axios.put(`${Delivery_Zones}/${id}`, data)
}

export const deleteDeliveryZone = (id) => {
  return axios.delete(`${Delivery_Zones}/${id}`)
}
