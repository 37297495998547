import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import swal from 'sweetalert'
import Preloader from '../preloader/Preloader'

//services
import { getDeliveryZoneSingle, createDeliveryZone, updateDeliveryZone } from 'src/services'

//components
import { BreadCrumb, Card } from 'src/components'

const CreateOrUpdate = () => {
  const history = useHistory()
  const { id } = useParams()
  const isUpdating = id !== undefined
  const [loading, setLoading] = useState(isUpdating)
  const [deliveryZoneList, setDeliveryZoneList] = useState({
    name: '',
    error_list: [],
  })

  useEffect(() => {
    if (isUpdating) {
      getDeliveryZoneSingle(id)
        .then((res) => {
          if (res.data.status === 200) {
            setDeliveryZoneList(res.data.delivery_zones)
          } else {
            history.push('/delivery_zones')
          }
          setLoading(false)
        })
        .catch(() => {
          history.push('/delivery_zones')
          setLoading(false)
        })
    }
  }, [id, history, isUpdating])

  const handleInput = (e) => {
    e.persist()
    setDeliveryZoneList({ ...deliveryZoneList, [e.target.name]: e.target.value })
  }

  const submitForm = (e) => {
    e.preventDefault()
    const data = deliveryZoneList

    const submitAction = isUpdating
      ? () => updateDeliveryZone(id, data)
      : () => createDeliveryZone(data)

    submitAction()
      .then((res) => {
        if (res.data.status === 200) {
          swal('Success', res.data.message, 'success')
          history.push('/delivery_zones')
        } else if (res.data.status === 400 || res.data.status === 422) {
          setDeliveryZoneList({ ...deliveryZoneList, error_list: res.data.errors || {} })
        }
      })
      .catch((error) => {
        swal('Error', 'An error occurred', 'error')
      })
  }

  if (loading) {
    return <Preloader />
  }

  const title = 'Delivery Zones'
  const isanupdate = isUpdating ? 'Update' : 'Add'

  const breadcrumb = [
    {
      title: title,
      name: 'Add Delivery Zones',
      subtitle: isanupdate,
      subtitleroute: '/delivery_zones',
    },
  ]

  const cardValues = [
    {
      title: 'Delivery Zones',
      addorupdate: isanupdate,
    },
  ]

  return (
    <div>
      <BreadCrumb data={breadcrumb} />

      <div className="content">
        <div className="card card-primary">
          <Card data={cardValues}>
            <form onSubmit={submitForm}>
              <div className="row">
                {/* First Name */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="pb-2">
                      Zone <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      onChange={handleInput}
                      value={deliveryZoneList.name}
                      placeholder="Zone"
                    />
                    <span className="text-danger">{deliveryZoneList?.error_list?.name}</span>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-primary mt-3">
                {isUpdating ? 'Update' : 'Save'}
              </button>
            </form>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default CreateOrUpdate
