import React from 'react'
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer'
import interreg from './../../../assets/PDFFonts/Inter-Regular.ttf'
import interbld from './../../../assets/PDFFonts/Inter-Bold.ttf'

Font.register({
  family: 'Inter',
  src: interreg,
})

Font.register({
  family: 'InterB',
  src: interbld,
})

// Create styles
const styles = StyleSheet.create({
  inline: {
    display: 'inline-block',
    fontSize: 12,
    lineHeight: 1.5,
  },
  heading: {
    fontSize: 17,
    lineHeight: 1.5,
    fontWeight: 700,
    fontFamily: 'InterB',
  },

  bold: {
    fontSize: 10,
    lineHeight: 1.5,
    fontWeight: 700,
    fontFamily: 'InterB',
  },

  bold9: {
    fontSize: 10,
    lineHeight: 1.5,
    fontWeight: 700,
    fontFamily: 'InterB',
  },

  reg: {
    fontSize: 7,
    lineHeight: 1.5,
    fontWeight: 400,
    fontFamily: 'Inter',
  },
  reg9: {
    fontSize: 9,
    lineHeight: 1.5,
    fontWeight: 400,
    fontFamily: 'Inter',
  },

  subheading: {
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: 700,
    fontFamily: 'InterB',
  },
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },

  wrapper: {
    margin: 10,
    border: '1px solid #E8E8E8',
    padding: 10,
    flexGrow: 1,
  },

  bottom: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    lineHeight: 1.2,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',

    lineHeight: 1.2,

    alignItems: 'self-start',
  },

  flexCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    lineHeight: 1.2,
  },

  margin_left: {
    marginLeft: 'auto',
  },

  line: {
    borderBottom: '1px solid #E8E8E8',
    margin: '10px 10px',
  },
  sub_tile: {
    fontSize: 16,
    lineHeight: 1.2,
    margin: '10px 0px',
  },

  tables: {
    backgroundColor: '#EFEFEF',
    margin: '5px 0px',
    border: '0.5px solid black',
  },

  td: {
    width: '33.33333%',
    padding: '5px 10px',
    border: '0.5px solid black',
  },
})

// Create Document Component
const MyDocument = (data) => {
  const dt = data.item

  const formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.flex}>
          <div>
            <div style={styles.flexCenter}>
              <Text style={styles.heading}>Order ID: </Text>
              <Text style={styles.heading}>&ensp;{dt.id}</Text>
              <Text style={styles.reg9}>&ensp; Created On : </Text>
              <Text style={styles.reg9}> &ensp;{formatDate(dt.created_at)}</Text>
            </div>
            <div style={styles.flexCenter}>
              <Text style={styles.reg9}>Status: &ensp;</Text>
              <Text style={styles.reg9}>Complete</Text>
            </div>
            <Text style={styles.reg}>Order Created By: &ensp;{dt.user.name ?? 'N/A'}</Text>
          </div>
          <View style={styles.margin_left}>
            <div style={styles.flexCenter}>
              <Text style={styles.bold}>Completed On: &ensp;</Text>
              <Text style={styles.reg}>{formatDate(dt.updated_at) ?? 'N/A'}</Text>
            </div>
            <div style={styles.flexCenter}>
              <Text style={styles.bold}>Scheduled Date: &ensp;</Text>
              <Text style={styles.reg}>{dt.scheduled_date ?? 'N/A'}</Text>
            </div>
            <div style={styles.flexCenter}>
              <Text style={styles.bold}>Tons Complete: &ensp;</Text>
              <Text style={styles.reg}>{dt.tons_complete_time ?? 'N/A'}</Text>
            </div>
            <div style={styles.flexCenter}>
              <Text style={styles.bold}>Dockets Complete: &ensp;</Text>
              <Text style={styles.reg}>{dt.dockets_complete_time ?? 'N/A'}</Text>
            </div>
          </View>
        </View>
        <div style={styles.line}></div>

        {/**Customer information */}
        <View>
          <Text style={styles.subheading}>Customer Information</Text>
          <div style={styles.flexCenter}>
            <Text style={styles.reg9}>Customer Code: </Text>
            <Text style={styles.bold9}>{dt.customer.id ?? 'N/A'}</Text>
          </div>
          <div style={styles.flexCenter}>
            <Text style={styles.reg9}>Customer Referrence: </Text>
            <Text style={styles.bold9}>{dt.customer_reference ?? 'N/A'}</Text>
          </div>

          <div style={styles.tables}>
            <div style={styles.flex}>
              <div style={styles.td}>
                <Text style={styles.bold}>Account Name</Text>
              </div>
              <div style={styles.td}>
                <Text style={styles.bold}>Account Contact Name</Text>
              </div>
              <div style={styles.td}>
                <Text style={styles.bold}>Terms</Text>
              </div>
              <div style={styles.td}>
                <Text style={styles.bold}>Address</Text>
              </div>
            </div>

            <tbody>
              <div style={styles.flex}>
                <div style={styles.td}>
                  <Text style={styles.reg}>{dt?.customer?.account_name ?? 'N/A'}</Text>
                </div>

                <div style={styles.td}>
                  <Text style={styles.reg}>{dt?.customer?.account_contact_name ?? 'N/A'}</Text>
                </div>
                <div style={styles.td}>
                  <Text style={styles.reg}>{dt?.customer_terms?.name ?? 'N/A'}</Text>
                </div>
                <div style={styles.td}>
                  <Text style={styles.reg}>
                    {dt?.customer?.customer_address[0].address ?? 'N/A'}
                  </Text>
                </div>
              </div>
            </tbody>
          </div>
          <div style={styles.tables}>
            <div style={styles.flex}>
              <div style={styles.td}>
                <Text style={styles.bold}>Customer Address</Text>
              </div>
              <div style={styles.td}>
                <Text style={styles.bold}>Delivery Zone</Text>
              </div>
              <div style={styles.td}>
                <Text style={styles.bold}>Address Contact Name</Text>
              </div>
              <div style={styles.td}>
                <Text style={styles.bold}>Address Contact Number</Text>
              </div>
            </div>

            <tbody>
              <div style={styles.flex}>
                <div style={styles.td}>
                  <Text style={styles.reg}>
                    {dt?.customer?.customer_address[0]?.address ?? 'N/A'}
                  </Text>
                </div>

                <div style={styles.td}>
                  <Text style={styles.reg}>
                    {dt?.customer?.customer_address[0]?.delivery_zones.name ?? 'N/A'}
                  </Text>
                </div>
                <div style={styles.td}>
                  <Text style={styles.reg}>
                    {dt?.customer?.customer_address[0]?.resident_name ?? 'N/A'}
                  </Text>
                </div>
                <div style={styles.td}>
                  <Text style={styles.reg}>
                    {dt?.customer?.customer_address[0]?.resident_contact ?? 'N/A'}
                  </Text>
                </div>
              </div>
            </tbody>
          </div>

          <div style={styles.flexCenter}>
            <Text style={styles.bold9}>Delevery Note: </Text>
            <Text style={styles.reg9}>{dt.driver_note ?? 'N/A'}</Text>
          </div>
        </View>
        <div style={styles.line}></div>

        {/**Order information */}
        <View>
          <Text style={styles.subheading}>Order information</Text>
          <div style={styles.flexCenter}>
            <Text style={styles.reg9}>Order Type: </Text>
            <Text style={styles.bold9}>{dt?.ordertype?.name ?? 'N/A'}</Text>
          </div>
          <div style={styles.flex}>
            <div style={styles.flexCenter}>
              <Text style={styles.reg9}>Sub Order Type: </Text>
              <Text style={styles.bold9}>{dt?.subordertype?.name ?? 'N/A'}</Text>
            </div>
            <div style={styles.margin_left}>
              <div style={styles.flexCenter}>
                <Text style={styles.reg9}>SKU Location: </Text>
                <Text style={styles.bold9}>{dt?.skulocation?.name ?? 'N/A'}</Text>
              </div>
            </div>
          </div>
          <div style={styles.tables}>
            <div style={styles.flex}>
              <div style={styles.td}>
                <Text style={styles.bold}>Agent</Text>
              </div>
              <div style={styles.td}>
                <Text style={styles.bold}>Machine</Text>
              </div>
              <div style={styles.td}>
                <Text style={styles.bold}>Operators</Text>
              </div>
              <div style={styles.td}>
                <Text style={styles.bold}>Order Delivery Zone</Text>
              </div>
            </div>

            <tbody>
              <div style={styles.flex}>
                <div style={styles.td}>
                  <Text style={styles.reg}>{dt?.agent?.name ?? 'N/A'}</Text>
                </div>

                <div style={styles.td}>
                  <Text style={styles.reg}>{dt?.machine?.name ?? 'N/A'}</Text>
                </div>
                <div style={styles.td}>
                  {dt.operator.map((data, i) => (
                    <Text key={i} style={styles.reg}>
                      {data?.operator?.fname && data?.operator?.lname
                        ? data.operator.fname + ' ' + data.operator.lname
                        : 'N/A'}
                    </Text>
                  ))}
                </div>
                <div style={styles.td}>
                  <Text style={styles.reg}>{dt.order_delivery_zone.name ?? 'N/A'}</Text>
                </div>
              </div>
            </tbody>
          </div>

          <div style={styles.flexCenter}>
            <Text style={styles.reg9}>Order Note: </Text>
            <Text style={styles.bold9}> {dt.order_note ?? 'N/A'}</Text>
          </div>
        </View>
        <div style={styles.line}></div>

        {/**Order List */}
        <View>
          <Text style={styles.subheading}>Product Information - Spread</Text>
          <div style={styles.flexCenter}>
            <Text style={styles.reg9}>Custom Spread Rate: </Text>
            <Text style={styles.bold9}> {dt.custom_spread_rate ?? 'N/A'}</Text>
          </div>

          <div style={styles.flex}>
            <div style={styles.flexCenter}>
              <Text style={styles.reg9}>Tons Ordered: </Text>
              <Text style={styles.bold9}> {dt.tons_ordered ?? 'N/A'}</Text>
            </div>
            <Text style={styles.reg9}>&ensp;|&ensp;</Text>

            <div style={styles.flexCenter}>
              <Text style={styles.reg9}>Tons Actual: </Text>
              <Text style={styles.bold9}> {dt.tons_actual ?? 'N/A'}</Text>
            </div>
          </div>

          {dt.order_list[0].order_list_products.length >= 0 ? (
            <div style={styles.tables}>
              <div style={styles.flex}>
                <div style={styles.td}>
                  <Text style={styles.bold}>id</Text>
                </div>
                <div style={styles.td}>
                  <Text style={styles.bold}>Name</Text>
                </div>
                <div style={styles.td}>
                  <Text style={styles.bold}>Quantity</Text>
                </div>
                <div style={styles.td}>
                  <Text style={styles.bold}>Quantity Type</Text>
                </div>
                <div style={styles.td}>
                  <Text style={styles.bold}>Xero Code</Text>
                </div>
                <div style={styles.td}>
                  <Text style={styles.bold}>Unit Price</Text>
                </div>
                <div style={styles.td}>
                  <Text style={styles.bold}>Spread Charge Rate</Text>
                </div>
              </div>

              <tbody>
                {dt.order_list[0].order_list_products.map((data, i) => (
                  <div key={i} style={styles.flex}>
                    <div style={styles.td}>
                      <Text style={styles.reg}>{data?.id ?? 'N/A'}</Text>
                    </div>
                    <div style={styles.td}>
                      <Text style={styles.reg}>{data?.product_name ?? 'N/A'}</Text>
                    </div>
                    <div style={styles.td}>
                      <Text style={styles.reg}>{data?.quantity ?? 'N/A'}</Text>
                    </div>
                    <div style={styles.td}>
                      <Text style={styles.reg}>{data?.quantitytype?.name ?? 'N/A'}</Text>
                    </div>
                    <div style={styles.td}>
                      {/* <Text style={styles.reg}>{data?.xero_code ?? 'N/A'}</Text> */}
                    </div>

                    <div style={styles.td}>
                      <Text style={styles.reg}>{data?.unit_price ?? 'N/A'}</Text>
                    </div>

                    <div style={styles.td}>
                      <Text style={styles.reg}>{data?.spreadchargerate?.name ?? 'N/A'}</Text>
                    </div>
                  </div>
                ))}
              </tbody>
            </div>
          ) : (
            <Text style={styles.reg9}>No Data Found</Text>
          )}
        </View>
        <div style={styles.line}></div>

        {/**Docket List */}
        <View>
          <Text style={styles.subheading}>Docket List</Text>

          <div style={styles.tables}>
            <div style={styles.flex}>
              <div style={styles.td}>
                <Text style={styles.bold}>Docket Number</Text>
              </div>
              <div style={styles.td}>
                <Text style={styles.bold}>Weight</Text>
              </div>
              <div style={styles.td}>
                <Text style={styles.bold}>Location</Text>
              </div>
            </div>

            <tbody>
              {dt.order_list[0].docket_numbers.map((data, i) => (
                <div key={i} style={styles.flex}>
                  <div style={styles.td}>
                    <Text style={styles.reg}>{data?.docket_number ?? 'N/A'}</Text>
                  </div>
                  <div style={styles.td}>
                    <Text style={styles.reg}>{data?.weight ?? 'N/A'}</Text>
                  </div>
                  <div style={styles.td}>
                    <Text style={styles.reg}>{data?.skulocation?.name ?? 'N/A'}</Text>
                  </div>
                </div>
              ))}
            </tbody>
          </div>
        </View>
        <div style={styles.line}></div>
        {/**Docket List */}

        <View>
          <Text style={styles.subheading}>Other Invoice Line Items</Text>

          <div style={styles.tables}>
            <div style={styles.flex}>
              <div style={styles.td}>
                <Text style={styles.bold}>Type</Text>
              </div>
              <div style={styles.td}>
                <Text style={styles.bold}>Description</Text>
              </div>
              <div style={styles.td}>
                <Text style={styles.bold}>Quantity</Text>
              </div>
              <div style={styles.td}>
                <Text style={styles.bold}>Amount</Text>
              </div>
              <div style={styles.td}>
                <Text style={styles.bold}>Total </Text>
              </div>
            </div>

            <tbody>
              {dt.line_items.map((data, i) => (
                <div key={i} style={styles.flex}>
                  <div style={styles.td}>
                    <Text style={styles.reg}>{data?.type ?? 'N/A'}</Text>
                  </div>
                  <div style={styles.td}>
                    <Text style={styles.reg}>{data?.description ?? 'N/A'}</Text>
                  </div>
                  <div style={styles.td}>
                    <Text style={styles.reg}>{data?.quantity ?? 'N/A'}</Text>
                  </div>
                  <div style={styles.td}>
                    <Text style={styles.reg}>{data?.amount ?? 'N/A'}</Text>
                  </div>
                  <div style={styles.td}>
                    <Text style={styles.reg}>{data?.quantity * data?.amount ?? 'N/A'}</Text>
                  </div>
                </div>
              ))}
            </tbody>
          </div>
        </View>
        <div style={styles.line}></div>

        <Text
          style={styles.bottom}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  )
}

export default MyDocument
