import { SubOrderType } from './routes'
import axios from 'axios'

export const getSubOrderType = () => {
  return axios.get(`${SubOrderType}`)
}

export const getSubOrderTypeSingle = (id) => {
  return axios.get(`${SubOrderType}/${id}/edit`)
}

export const createSubOrderType = (data) => {
  return axios.post(`${SubOrderType}`, data)
}

export const updateSubOrderType = (id, data) => {
  return axios.put(`${SubOrderType}/${id}`, data)
}

export const deleteSubOrderType = (id) => {
  return axios.delete(`${SubOrderType}/${id}`)
}
