import React from 'react'
import { NavLink } from 'react-router-dom'

const BreadCrumb = ({ data }) => {
  return (
    <div>
      {data.map((value) => (
        <>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <NavLink to="/orders">Orders</NavLink>
              </li>
              {value.subtitleroute ? (
                <>
                  <li className="breadcrumb-item active" aria-current="page">
                    <NavLink to={value.subtitleroute}>{value.title}</NavLink>
                  </li>
                </>
              ) : value.name === 'order' ? (
                ''
              ) : (
                <li className="breadcrumb-item active" aria-current="page">
                  {value.title}
                </li>
              )}

              {value.subtitle ? (
                <li className="breadcrumb-item active" aria-current="page">
                  {value.subtitle}
                </li>
              ) : (
                ''
              )}
            </ol>
          </nav>
          <h3 className="text-dark">{value.title}</h3>
        </>
      ))}
    </div>
  )
}

export default BreadCrumb
