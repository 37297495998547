import React from 'react'
import { Link } from 'react-router-dom'

const Button = ({ data }) => {
  return (
    <div>
      {data.map((value) => (
        <Link key={value} className={value.variants} to={value.routes}>
          {value.title}
        </Link>
      ))}
    </div>
  )
}

export default Button
