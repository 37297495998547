import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Provider } from 'react-redux'
import store from './store'
import { UserContext } from './context/UserContext'

ReactDOM.render(
  <Provider store={store}>
    <UserContext>
      <App />
    </UserContext>
  </Provider>,
  document.getElementById('root'),
)
