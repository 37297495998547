import React, { useState, useEffect } from 'react'
import { useHistory, NavLink, useParams } from 'react-router-dom'
import swal from 'sweetalert'
import Preloader from '../preloader/Preloader'

//components
import { DataTableItem, UserRoute, BreadCrumb, Card } from 'src/components'

//services
import { createLineItems, updateLineItems, getLineItemSingle } from 'src/services'

function CreateOrUpdate() {
  const history = useHistory()
  const { id } = useParams()
  const isUpdating = id !== undefined
  const [loading, setLoading] = useState(isUpdating)
  const [quantityInput, setQuantityInput] = useState({
    name: '',
    xero_code: '',
    error_list: {},
  })

  useEffect(() => {
    if (isUpdating) {
      getLineItemSingle(id)
        .then((res) => {
          if (res.data.status === 200) {
            setQuantityInput(res.data.line_item)
          } else {
            history.push('/line_items')
          }
          setLoading(false)
        })
        .catch(() => {
          history.push('/line_items')
          setLoading(false)
        })
    }
  }, [id, history, isUpdating])

  const handleInput = (e) => {
    e.persist()
    setQuantityInput({ ...quantityInput, [e.target.name]: e.target.value })
  }

  const submitForm = (e) => {
    e.preventDefault()
    const data = { name: quantityInput.name, xero_code: quantityInput.xero_code }

    const submitAction = isUpdating ? () => updateLineItems(id, data) : () => createLineItems(data)

    submitAction()
      .then((res) => {
        if (res.data.status === 200) {
          swal('Success', res.data.message, 'success')
          history.push('/line_items')
        } else if (res.data.status === 400 || res.data.status === 422) {
          setQuantityInput({ ...quantityInput, error_list: res.data.errors || {} })
        }
      })
      .catch((error) => {
        swal('Error', 'An error occurred', 'error')
      })
  }

  if (loading) {
    return <Preloader />
  }

  const title = 'Additional Line Items'
  const isanupdate = isUpdating ? 'Update' : 'Add'

  const breadcrumb = [
    {
      title: title,
      name: 'Add Line Items',
      subtitle: isanupdate,
      subtitleroute: '/line_items',
    },
  ]

  const cardValues = [
    {
      title: 'Line Items',
      addorupdate: isanupdate,
    },
  ]

  return (
    <div>
      <BreadCrumb data={breadcrumb} />

      <div className="content">
        <div className="card card-primary">
          <Card data={cardValues}>
            <form onSubmit={submitForm}>
              <div className="row">
                {/* Line Item Name */}
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>
                      Line Item Name <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      onChange={handleInput}
                      value={quantityInput.name}
                      placeholder="Line Item Name"
                    />
                    <span className="text-danger">{quantityInput.error_list?.name || ''}</span>
                  </div>
                </div>

                {/* Xero Code */}
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>
                      Xero Code <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="xero_code"
                      onChange={handleInput}
                      value={quantityInput.xero_code}
                      placeholder="Xero Code"
                    />
                    <span className="text-danger">{quantityInput.error_list?.xero_code || ''}</span>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-primary mt-3">
                {isUpdating ? 'Update' : 'Save'}
              </button>
            </form>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default CreateOrUpdate
