import React, { useState, useEffect } from 'react'
import { useHistory, NavLink, useParams } from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert'
import Preloader from 'src/views/preloader/Preloader'
import Select from 'react-select'
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput'

function Create() {
  const history = useHistory()

  let { id } = useParams()
  const [loading, setLoading] = useState()
  const [errorList, setError] = useState([])
  const [inputList, setInputList] = useState({
    id: '',
    name: '',
    guard_name: 'web',
  })

  const handleInput = (e) => {
    e.persist()
    setInputList({ ...inputList, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    if (id) {
      setLoading(true)
      axios.get(`api/edit_permission/${id}`).then((res) => {
        if (res.data.status === 200) {
          setInputList(res.data.permission)
        } else if (res.data.status === 404) {
          swal('Error', res.data.message, 'error')
          history.push('/permissions')
        }
        setLoading(false)
      })
    }
  }, [history, id])

  const submitRoles = (e) => {
    e.preventDefault()

    if (id) {
      axios.put(`/api/update_permission/${id}`, inputList).then((res) => {
        if (res.data.status === 200) {
          swal('Success', res.data.message, 'success')
          setError([])
          history.push('/permissions')
        } else if (res.data.status === 422) {
          setError(res.data.errors)
        }
      })
    } else {
      axios.post(`/api/create_permissions`, inputList).then((res) => {
        if (res.data.status === 200) {
          swal('Success', res.data.message, 'success')
          history.push('/permissions')
          setError([])
        } else if (res.data.status === 400) {
          setError(res.data.errors)
        }
      })
    }
  }

  if (loading) {
    return <Preloader />
  }
  return (
    <div className="content-wrapper">
      <nav aria-label="breadcrumb p-3">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <NavLink to="/permissions">Permissions</NavLink>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {id ? 'Update role' : 'Add role'}
          </li>
        </ol>
      </nav>
      <h3 className="text-dark">Role and Permissions</h3>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6 p-0"></div>
          </div>
        </div>
      </div>

      {/* body content dashboard */}
      <div className="content">
        <div className="card">
          <div className="card-header">
            <h5 className="card-title">{id ? 'Update role' : 'Add role'}</h5>
          </div>
          <div className="card-body">
            <form onSubmit={submitRoles} id="product_form">
              <div className="card-body">
                <div className="row">
                  {/* Name */}
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="pb-2">
                        Permission Name <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        onChange={handleInput}
                        value={inputList.name}
                        placeholder="Permission Name"
                      />
                      <span className="text-danger">{errorList.name}</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* <pre>{JSON.stringify(inputList, null, 2)}</pre> */}

              <div className="mx-3">
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Create
