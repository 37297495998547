import React, { useState, useEffect } from 'react'
import { useHistory, NavLink, useParams } from 'react-router-dom'
import swal from 'sweetalert'
import Preloader from '../preloader/Preloader'

//services
import { getAgents, getAgentSingle, createAgents, updateAgents, deleteAgents } from 'src/services'

//components
import { DataTableItem, UserRoute, BreadCrumb, Card } from 'src/components'

function CreateOrUpdate() {
  const history = useHistory()
  const { id } = useParams()
  const isUpdating = id !== undefined
  const [loading, setLoading] = useState(isUpdating)
  const [agentList, setAgentList] = useState({
    name: '',
    error_list: {},
  })

  useEffect(() => {
    if (isUpdating) {
      getAgentSingle(id)
        .then((res) => {
          if (res.data.status === 200) {
            setAgentList(res.data.agent)
          } else {
            history.push('/agents')
          }
          setLoading(false)
        })
        .catch(() => {
          history.push('/agents')
          setLoading(false)
        })
    }
  }, [id, history, isUpdating])

  const handleInput = (e) => {
    e.persist()
    setAgentList({ ...agentList, [e.target.name]: e.target.value })
  }

  const submitForm = (e) => {
    e.preventDefault()
    const data = agentList

    const submitAction = isUpdating ? () => updateAgents(id, data) : () => createAgents(data)

    submitAction()
      .then((res) => {
        if (res.data.status === 200) {
          swal('Success', res.data.message, 'success')
          history.push('/agents')
        } else if (res.data.status === 400 || res.data.status === 422) {
          setAgentList({ ...agentList, error_list: res.data.errors || {} })
        }
      })
      .catch((error) => {
        swal('Error', 'An error occurred', 'error')
      })
  }

  if (loading) {
    return <Preloader />
  }

  const title = 'Agents'
  const isanupdate = isUpdating ? 'Update' : 'Add'

  const breadcrumb = [
    {
      title: title,
      name: 'Add Agents',
      subtitle: isanupdate,
      subtitleroute: '/agents',
    },
  ]

  const cardValues = [
    {
      title: 'Line Items',
      addorupdate: isanupdate,
    },
  ]

  return (
    <div>
      <BreadCrumb data={breadcrumb} />

      <div className="content">
        <div className="card card-primary">
          <Card data={cardValues}>
            <form onSubmit={submitForm}>
              <div className="row">
                {/* Agent Name */}
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>
                      Agent Name <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control mt-2"
                      type="text"
                      name="name"
                      onChange={handleInput}
                      value={agentList?.name}
                      placeholder="Agent Name"
                    />
                    <span className="text-danger">{agentList?.error_list?.name || ''}</span>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-primary mt-3">
                {isUpdating ? 'Update' : 'Save'}
              </button>
            </form>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default CreateOrUpdate
