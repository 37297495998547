import { BlobProvider, PDFViewer } from '@react-pdf/renderer'
import React from 'react'
import DailyReport from './PDF/Daily'
import Preloader from 'src/views/preloader/Preloader'
import { useState } from 'react'
import Swal from 'sweetalert2'
import { useEffect } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

const DailyReports = () => {
  const [loading, setLoading] = useState(true)
  const [reportData, setreportData] = useState([])
  const [map_data, setMapData] = useState({})
  const history = useHistory()

  useEffect(() => {
    axios.get(`/api/show_order`).then((res) => {
      if (res.data.status === 200) {
        setMapData({
          dzone: res.data.dzone,
          otype: res.data.otype,
          machine: res.data.machine,
        })

        setreportData(res.data.today)
      } else if (res.data.status === 500) {
        Swal.fire({
          icon: 'warning',
          title: 'warning',
          text: res.data.message,
        })
        history.push('/')
      }

      setLoading(false)
    })
  }, [null])

  if (loading) {
    return <Preloader />
  }
  console.log(reportData)
  return (
    <>
      <h1 className="mb-4">Daily Report</h1>

      {reportData.length !== 0 && map_data ? (
        <>
          <BlobProvider document={<DailyReport item={reportData} map_data={map_data} />}>
            {({ url, loading, error }) =>
              !loading ? (
                <a
                  href={url}
                  className="btn btn-block btn-outline-info btn-sm"
                  rel="noreferrer"
                  target="_blank"
                >
                  Download Report
                </a>
              ) : (
                <a
                  className="btn btn-block btn-outline-info btn-sm"
                  href={url}
                  rel="noreferrer"
                  target="_blank"
                >
                  Preparing Report
                </a>
              )
            }
          </BlobProvider>
        </>
      ) : (
        <p>No data found</p>
      )}
    </>
  )
}

export default DailyReports
