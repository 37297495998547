import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import swal from 'sweetalert'
import Preloader from '../preloader/Preloader'
import { CFormSelect } from '@coreui/react'

//services
import { getProductSingle, createProducts, updateProducts } from 'src/services'

//components
import { BreadCrumb, Card } from 'src/components'
const CreateOrUpdate = () => {
  const history = useHistory()
  const { id } = useParams()
  const isUpdating = id !== undefined
  const [loading, setLoading] = useState(isUpdating)
  const [productList, setProductList] = useState({
    product_name: '',
    product_code: '',
    error_list: [],
  })

  useEffect(() => {
    if (isUpdating) {
      getProductSingle(id)
        .then((res) => {
          if (res.data.status === 200) {
            setProductList(res.data.product)
          } else {
            history.push('/products')
          }
          setLoading(false)
        })
        .catch(() => {
          history.push('/products')
          setLoading(false)
        })
    }
  }, [id, history, isUpdating])

  const handleInput = (e) => {
    e.persist()
    setProductList({ ...productList, [e.target.name]: e.target.value })
  }

  const submitForm = (e) => {
    e.preventDefault()
    const data = productList

    const submitAction = isUpdating ? () => updateProducts(id, data) : () => createProducts(data)

    submitAction()
      .then((res) => {
        if (res.data.status === 200) {
          swal('Success', res.data.message, 'success')
          history.push('/products')
        } else if (res.data.status === 400 || res.data.status === 422) {
          setProductList({ ...productList, error_list: res.data.errors || {} })
        }
      })
      .catch((error) => {
        swal('Error', 'An error occurred', 'error')
      })
  }

  if (loading) {
    return <Preloader />
  }

  const title = 'Products'
  const isanupdate = isUpdating ? 'Update' : 'Add'

  const breadcrumb = [
    {
      title: title,
      name: 'Add' + title,
      subtitle: isanupdate,
      subtitleroute: '/products',
    },
  ]

  const cardValues = [
    {
      title: title,
      addorupdate: isanupdate,
    },
  ]

  return (
    <div>
      <BreadCrumb data={breadcrumb} />

      <div className="content">
        <div className="card card-primary">
          <Card data={cardValues}>
            <form onSubmit={submitForm}>
              <div className="row">
                {/* Product Code */}
                <div className="col-lg-4">
                  <div className="form-group">
                    <label className="pb-2">
                      Product Code <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="product_code"
                      onChange={handleInput}
                      value={productList.product_code}
                      placeholder="Product Code"
                    />
                    <span className="text-danger">{productList?.error_list?.product_code}</span>
                  </div>
                </div>

                {/* Product Name */}
                <div className="col-lg-4">
                  <div className="form-group">
                    <label className="pb-2">
                      Product Name <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="product_name"
                      onChange={handleInput}
                      value={productList.product_name}
                      placeholder="Product Name"
                    />
                    <span className="text-danger">{productList?.error_list?.product_name}</span>
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-primary mt-3">
                {isUpdating ? 'Update' : 'Save'}
              </button>
            </form>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default CreateOrUpdate
