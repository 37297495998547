import React, { useState, useEffect } from 'react'
import { useHistory, NavLink, useParams } from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert'
import Preloader from 'src/views/preloader/Preloader'
import Select from 'react-select'

function Create() {
  const history = useHistory()
  const [customerSelectOptoins, setCustomerSelectedOptions] = useState([])
  let { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [role_id, setrole_id] = useState(0)

  const [errorList, setError] = useState([])
  const [inputList, setInputList] = useState({
    id: '',
    name: '',
    email: '',
    password: '',
    role_as: '1',
  })

  const handleInput = (e) => {
    e.persist()
    setInputList({ ...inputList, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    if (id) {
      setLoading(true)
      axios.get(`api/edit_users/${id}`).then((res) => {
        if (res.data.status === 200) {
          setInputList(res.data.user)

          setrole_id({
            value: res.data.user?.roles[0]?.id,
            label: res.data.user?.roles[0]?.name,
          })
        } else if (res.data.status === 404) {
          swal('Error', res.data.message, 'error')
          history.push('/sub_order_types')
        }
        setLoading(false)
      })
    }

    axios.get(`api/show_roles`).then((res) => {
      if (res.data.status === 200) {
        res.data.roles.map((data) => {
          let daratow = {
            value: data.id,
            label: data.name,
          }
          setCustomerSelectedOptions((data) => [...data, daratow])

          return null
        })
      } else if (res.data.status === 404) {
        swal('Error', res.data.message, 'error')
        history.push('/sub_order_types')
      }
      setLoading(false)
    })
  }, [history, id])

  const handleChangeCustomerID = (e) => {
    const { value } = e

    const list = { ...inputList }
    list.role_as = value
    setInputList(list)
  }

  const submitProductMix = (e) => {
    e.preventDefault()

    if (id) {
      axios.put(`/api/update_user/${id}`, inputList).then((res) => {
        if (res.data.status === 200) {
          swal('Success', res.data.message, 'success')
          setError([])
          history.push('/users')
        } else if (res.data.status === 422) {
          setError(res.data.errors)
        }
      })
    } else {
      axios.post(`/api/create_users`, inputList).then((res) => {
        if (res.data.status === 200) {
          swal('Success', res.data.message, 'success')
          history.push('/users')
          setError([])
        } else if (res.data.status === 400) {
          setError(res.data.errors)
        }
      })
    }
  }

  if (loading) {
    return <Preloader />
  }
  return (
    <div className="content-wrapper">
      <nav aria-label="breadcrumb p-3">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <NavLink to="/users">Users</NavLink>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {id ? 'Update User' : 'Add User'}
          </li>
        </ol>
      </nav>
      <h3 className="text-dark">User</h3>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6 p-0"></div>
          </div>
        </div>
      </div>

      {/* body content dashboard */}
      <div className="content">
        <div className="card">
          <div className="card-header">
            <h5 className="card-title">{id ? 'Update User' : 'Add User'}</h5>
          </div>
          <div className="card-body">
            <form onSubmit={submitProductMix} id="product_form">
              <div className="card-body">
                <div className="row">
                  {/* Name */}
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label className="pb-2">
                        Name <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        onChange={handleInput}
                        value={inputList.name}
                        placeholder="Name"
                      />
                      <span className="text-danger">{errorList.name}</span>
                    </div>
                  </div>

                  {/* Code */}
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label className="pb-2">
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        onChange={handleInput}
                        value={inputList.email}
                        placeholder="Email"
                      />
                      <span className="text-danger">{errorList.email}</span>
                    </div>
                  </div>

                  {/* Password */}
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label className="pb-2">
                        Password <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="password"
                        onChange={handleInput}
                        value={inputList.password}
                        placeholder="Password"
                      />
                      <span className="text-danger">{errorList.password}</span>
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="form-group">
                      <label className="pb-2">
                        Roles <span className="text-danger">*</span>
                      </label>
                      <Select
                        onChange={(e) => handleChangeCustomerID(e)}
                        aria-label="Default select example"
                        name="customer_id"
                        options={customerSelectOptoins}
                        defaultValue={role_id}
                      />
                      <span className="text-danger">{errorList.password}</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* <pre>{JSON.stringify(inputList, null, 2)}</pre> */}

              <div className="mx-3">
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Create
