import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilNotes, cilSettings, cilAddressBook, cilBarChart, cilSpeedometer } from '@coreui/icons'
import { CNavGroup, CNavItem /* , CNavTitle */ } from '@coreui/react'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },

  {
    component: CNavItem,
    name: 'Orders',
    to: '/orders',
    icon: <CIcon icon={cilBarChart} className="cil-3d" customClassName="nav-icon" />,
    permission: 'access orders',
  },
  {
    component: CNavGroup,
    name: 'Reports',
    to: '/setting',
    icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
    permission: 'access orders',
    items: [
      {
        component: CNavItem,
        name: 'Daily Report',
        to: '/daily-reports',
        permission: 'access orders',
      },
      {
        component: CNavItem,
        name: 'Custom Report',
        to: '/custom-reports',
        permission: 'access orders',
      },
      {
        component: CNavItem,
        name: 'Spread Report',
        to: '/spread-reports',
        permission: 'access orders',
      },
    ],
  },
  {
    component: CNavItem,
    name: 'Customers',
    to: '/customers',
    icon: <CIcon icon={cilAddressBook} customClassName="nav-icon" />,
    permission: 'access customers',
  },
  {
    component: CNavItem,
    name: 'Xero',
    to: '/Xero',
    icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
    permission: 'access xero',
  },

  {
    permission: 'access settings',
    component: CNavGroup,
    name: 'Settings',
    to: '/setting',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Agents',
        to: '/agents',
        permission: 'access agents',
      },
      {
        component: CNavItem,
        name: 'Additional Line Items',
        to: '/line_items',
        permission: 'access line items',
      },
      {
        component: CNavItem,
        name: 'Crops',
        to: '/crops',
        permission: 'access crops',
      },
      {
        component: CNavItem,
        name: 'Customer Terms',
        to: '/customer_terms',
        permission: 'access customer terms',
      },
      {
        component: CNavItem,
        name: 'Delivery Zones',
        to: '/delivery_zones',
        permission: 'access delivery zones',
      },
      {
        component: CNavItem,
        name: 'Machines',
        to: '/machines',
        permission: 'access machines',
      },
      {
        component: CNavItem,
        name: 'Order Types',
        to: '/order_types',
        permission: 'access order types',
      },
      {
        component: CNavItem,
        name: 'Operators',
        to: '/operators',
        permission: 'access operators',
      },
      {
        component: CNavItem,
        name: 'Products',
        to: '/products',
        permission: 'access products',
      },
      {
        component: CNavItem,
        name: 'Quantity',
        to: '/quantity',
        permission: 'access quantity',
      },
      {
        component: CNavItem,
        name: 'SKU Location',
        to: '/sku_location',
        permission: 'access sku location',
      },
      {
        component: CNavItem,
        name: 'Spread Charge Rate',
        to: '/spread_charge_rate',
        permission: 'access spread charge rate',
      },
      {
        component: CNavItem,
        name: 'Sub Order Types',
        to: '/sub_order_types',
        permission: 'access sub order types',
      },
      {
        component: CNavItem,
        name: 'Users',
        to: '/users',
        permission: 'access users',
      },
      {
        component: CNavItem,
        name: 'Xero',
        to: '/Xero',
        permission: 'access xero',
      },
    ],
  },
]

export default _nav
