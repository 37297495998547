import { Machines } from './routes'
import axios from 'axios'

export const getMachines = () => {
  return axios.get(`${Machines}`)
}

export const getMachineSingle = (id) => {
  return axios.get(`${Machines}/${id}/edit`)
}

export const createMachines = (data) => {
  return axios.post(`${Machines}`, data)
}

export const updateMachines = (id, data) => {
  return axios.put(`${Machines}/${id}`, data)
}

export const deleteMachines = (id) => {
  return axios.delete(`${Machines}/${id}`)
}
