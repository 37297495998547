import React, { useState, useEffect } from 'react'
import { useHistory, NavLink, useParams } from 'react-router-dom'
import swal from 'sweetalert'
import Preloader from '../preloader/Preloader'
import { CFormCheck } from '@coreui/react'
import useUnsavedChangesWarning from 'src/hooks/UnsavedChangesWarning'

//services
import { getCustomerTermSingle, createCustomerTerms, updateCustomerTerms } from 'src/services'

//components
import { BreadCrumb, Card } from 'src/components'

const CreateOrUpdate = () => {
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning()
  const history = useHistory()
  const { id } = useParams()
  const isUpdating = id !== undefined
  const [loading, setLoading] = useState(isUpdating)
  const [customerTermsList, setCustomerTermsList] = useState({
    name: '',
    highlight: '0',
    error_list: [],
  })

  useEffect(() => {
    if (isUpdating) {
      getCustomerTermSingle(id)
        .then((res) => {
          if (res.data.status === 200) {
            setCustomerTermsList(res.data.customer_terms)
          } else {
            history.push('/customer_terms')
          }
          setLoading(false)
        })
        .catch(() => {
          history.push('/customer_terms')
          setLoading(false)
        })
    }
  }, [id, history, isUpdating])

  const handleInput = (e) => {
    e.persist()
    setCustomerTermsList({ ...customerTermsList, [e.target.name]: e.target.value })
  }

  const handleCheckBox = (e) => {
    setDirty()

    const { name, checked } = e.target
    const list = { ...customerTermsList }

    if (checked) {
      list[name] = '1'
      setCustomerTermsList(list)
    } else {
      list[name] = '0'
      setCustomerTermsList(list)
    }
  }

  const submitForm = (e) => {
    e.preventDefault()
    const data = customerTermsList

    const submitAction = isUpdating
      ? () => updateCustomerTerms(id, data)
      : () => createCustomerTerms(data)

    submitAction()
      .then((res) => {
        if (res.data.status === 200) {
          swal('Success', res.data.message, 'success')
          history.push('/customer_terms')
        } else if (res.data.status === 400 || res.data.status === 422) {
          setCustomerTermsList({ ...customerTermsList, error_list: res.data.errors || {} })
        }
      })
      .catch((error) => {
        swal('Error', 'An error occurred', 'error')
      })
  }

  if (loading) {
    return <Preloader />
  }

  const title = 'Customer Terms'
  const isanupdate = isUpdating ? 'Update' : 'Add'

  const breadcrumb = [
    {
      title: title,
      name: 'Add Customer Terms',
      subtitle: isanupdate,
      subtitleroute: '/customer_terms',
    },
  ]

  const cardValues = [
    {
      title: 'Customer Terms',
      addorupdate: isanupdate,
    },
  ]

  return (
    <div>
      <BreadCrumb data={breadcrumb} />

      <div className="content">
        <div className="card card-primary">
          <Card data={cardValues}>
            <form onSubmit={submitForm}>
              <div className="row">
                {/* First Name */}
                <div className="col-4">
                  <div className="form-group">
                    <label className="pb-2">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      onChange={handleInput}
                      value={customerTermsList.name}
                      placeholder="Name"
                    />
                    <span className="text-danger">{customerTermsList?.error_list?.name || ''}</span>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <CFormCheck
                      onChange={(e) => handleCheckBox(e)}
                      name="highlight"
                      className="mt-4 pt-2"
                      checked={Number(customerTermsList.highlight)}
                      label="Highlight"
                    />
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-primary mt-3">
                {isUpdating ? 'Update' : 'Save'}
              </button>
            </form>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default CreateOrUpdate
