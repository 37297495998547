import React, { useState, useEffect, useContext } from 'react'
import { NavLink, Link } from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert2'
import Preloader from '../preloader/Preloader'
import $ from 'jquery'
import DataTable from 'datatables.net'

import { UserContext, Usercntxt } from './../../context/UserContext'
const User = () => {
  const user = useContext(Usercntxt)

  const [loading, setLoading] = useState(true)
  const [quantityList, setQuantityList] = useState([])

  useEffect(() => {
    axios.get(`/api/show_users`).then((res) => {
      if (res.data.status === 200) {
        setQuantityList(res.data.user)
      }
      setTimeout(() => {
        $('#dataTable').DataTable({
          scrollX: false,
          pageLength: 10,
        })
      }, 10)
      setLoading(false)
    })
  }, [])

  const deleteAgent = (e, id) => {
    e.preventDefault()

    const thisClicked = e.currentTarget

    swal
      .fire({
        title: 'Are you sure you want to proceed?',
        text: 'This will completely delete the user permanently',
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'Cancel!',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Proceed!',
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios.delete(`/api/delete_user/${id}`).then((res) => {
            if (res.data.status === 200) {
              swal.fire('Deleted!', 'User has been deleted', 'success')
              thisClicked.closest('tr').remove()
            } else if (res.data.status === 404) {
              swal.fire('Error', res.data.message, 'error')
            }
          })
        } else if (result.isDenied) {
          swal.fire('Changes are not saved', '', 'info')
        }
      })
  }

  var viewCustomer_HTMLTable = ''
  if (loading) {
    return <Preloader />
  } else {
    viewCustomer_HTMLTable = quantityList.map((item, i) => {
      return (
        <tr key={item.id}>
          <td>{i + 1}</td>
          <td>{item.name}</td>
          <td>{item.email}</td>
          <td>
            <Link
              to={`/users/update/${item.id}`}
              className="btn btn-block btn-outline-info btn-sm me-2"
            >
              <i className="far fa-edit"></i> Edit
            </Link>
            <button
              type="button"
              onClick={(e) => deleteAgent(e, item.id)}
              className="btn btn-block btn-outline-danger btn-sm"
            >
              Delete
            </button>
          </td>
        </tr>
      )
    })
  }

  return (
    <div className="content-wrapper">
      <nav aria-label="breadcrumb p-3">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <NavLink to="/">Dashboard</NavLink>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            User
          </li>
        </ol>
      </nav>
      <h3 className="text-dark">Users</h3>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6 p-0">
              <NavLink className="btn btn-primary mt-3 mr-2" to="/users/create">
                Add user
              </NavLink>
              <NavLink className="btn btn-warning mt-3 mr-2 ms-3" to="/roles">
                Roles
              </NavLink>
              {user.can('admin') && (
                <NavLink className="btn btn-info mt-3 mr-2 ms-3" to="/permissions">
                  Permissions
                </NavLink>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* body content dashboard */}
      <div className="content">
        <div className="card">
          <div className="card-header">
            <h5 className="card-title">NQL - SKU Location</h5>
          </div>
          <div className="card-body">
            <table id="dataTable" className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>{viewCustomer_HTMLTable}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default User
