import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert'
import Preloader from '../preloader/Preloader'

const Profile = () => {
  const [loading, setLoading] = useState(true)
  const [errorList, setError] = useState([])
  const [inputList, setInputList] = useState({
    name: '',
  })

  useEffect(() => {
    axios.get(`/api/edit_profile`).then((res) => {
      if (res.data.status === 200) {
        setInputList(res.data.user)
        setLoading(false)
      }
    })
  }, [])

  const handleInput = (e) => {
    e.persist()
    setInputList({ ...inputList, [e.target.name]: e.target.value })
  }

  const submitUser = (e) => {
    e.preventDefault()
    axios.put(`/api/update_profile/${inputList.id}`, inputList).then((res) => {
      if (res.data.status === 200) {
        swal('Success', res.data.message, 'success')
        setError([])
      } else if (res.data.status === 400) {
        setError(res.data.errors)
      }
    })
  }

  if (loading) {
    return <Preloader />
  }
  return (
    <div className="content-wrapper">
      <nav aria-label="breadcrumb p-3">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <NavLink to="/">Dashboard</NavLink>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Profile
          </li>
        </ol>
      </nav>
      <h3 className="text-dark">Profile</h3>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6 p-0"></div>
          </div>
        </div>
      </div>

      {/* body content dashboard */}
      <div className="content">
        <div className="card">
          <div className="card-header">
            <h5 className="card-title">Profile</h5>
          </div>
          <div className="card-body">
            <form onSubmit={submitUser} id="product_form">
              <div className="card-body">
                <div className="row">
                  {/* Profile Name */}
                  <div className="col-lg-6 mb-3">
                    <div className="form-group">
                      <label className="pb-2">
                        Name <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        onChange={handleInput}
                        value={inputList.name}
                        placeholder="Name"
                      />
                      <span className="text-danger">{errorList.name}</span>
                    </div>
                  </div>
                  {/* Profile Email */}
                  <div className="col-lg-6 mb-3">
                    <div className="form-group">
                      <label className="pb-2">
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        onChange={handleInput}
                        value={inputList.email}
                        placeholder="Email"
                      />
                      <span className="text-danger">{errorList.email}</span>
                    </div>
                  </div>
                </div>
                {/* <pre>{JSON.stringify(inputList, null, 2)}</pre> */}
              </div>
              <div className="mx-3">
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
