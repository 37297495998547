import React from 'react'

const preloader = () => {
  return (
    <>
      <div className="preloader p-2">
        <div className="spinner-border text-dark" role="status"></div>
      </div>
    </>
  )
}

export default preloader
